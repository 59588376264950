import * as React from "react";
import {
	Avatar,
	Box,
	Button,
	Flex,
	HStack,
	List,
	ListIcon,
	ListItem,
	Menu,
	MenuButton,
	MenuDivider,
	Progress,
	MenuItem,
	MenuList,
	Spinner,
	VStack,
	Heading,
	Text,
	Image,
} from "@chakra-ui/react";
import styles from "./App.module.scss";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import { Logo } from "../../components/Logo/Logo";
import { ChatbotList } from "../ChatbotList/ChatbotList";
import { Settings } from "../Settings/Settings";
import { getUserProfile } from "../../services/userServices";
import { CurrentUser, User } from "../../services/appConfig";
import { DemoChatbots } from "../DemoChatbots/DemoChatbots";
import classNames from "classnames";
import { formatNumber } from "../../utils/commonUtils";
import { Users } from "../Users/Users";
import { UserProfile } from "../UserProfile/UserProfile";
import { useHistory } from 'react-router-dom';
import { PrivateRoute } from "../../components/PrivateRoute/PrivateRoute";
import EditChatbot from "../EditChatbot/EditChatbot";
import { InviteUsers } from "../Invite Users/InviteUsers"
import { InviteUsersHistory } from "../Invite Users/InviteUsersHistory"
import { MyProfile } from "../Users/MyProfile";
import { EmailTemplateSuperadmin } from "../EmailTemplateSuperadmin/EmailTemplateSuperadmin";
import { HiOutlineMailOpen } from "react-icons/hi";
import { EmailCampaignsAdmin } from "../EmailCampaignsAdmin/EmailCampaignsAdmin";
import { LeadsHistory } from "../LeadsHistory/LeadsHistory";
import profileIcon from "../../components/Icons/profileIcon.svg";
import logoutIcon from "../../components/Icons/logoutIcon.svg";
import ProjectIcon from "../../components/Icons/ProjectIcon.svg";
import EmailCampaignsIcon from "../../components/Icons/EmailCampaignsIcon.svg";
import SubscriptionIcon from "../../components/Icons/SubscriptionIcon.svg";
import UserIcon from "../../components/Icons/usersIcon.svg";
import LeadsDataiIcon from "../../components/Icons/LeadsDataiIcon.svg";
import { UltimamailSettings } from "../UltimamailSettings/UltimamailSettings";
import { UltimamailSettingsHistory } from "../UltimamailSettings/UltimamailSettingsHistory";
import { SettingDetails } from "../UltimamailSettings/SettingDetails";
import UltimamailSettingsIcon from "../../components/Icons/UltimamailSettings.svg"
import nookies from 'nookies';
interface AppProps {
	onLoginOut: () => void;
}

export const App = (props: AppProps) => {
	const [userData, setUserData] = React.useState<User | null>(null);
	const history = useHistory();
	const cookies = nookies.get(null);
	let userPresent = cookies.accesstoken;

	const [isAuthenticated, setIsAuthenticated] = React.useState(!!userPresent)

	React.useEffect(() => {
		async function fetchData() {
			try {
				const response = await getUserProfile();

				CurrentUser.set(response.data);
				setUserData(response.data);

			} catch (error) {

			} finally {
			}
		}
		fetchData();
	}, []);

	const editChatbotPath = React.useMemo(() => '/app/edit-chatbot/:chatbotId', []);

	const getUsageComponent = React.useCallback(() => {


		if (!userData) return null

		try {
			let usage: any = (userData.monthUsage.count * 100) / userData?.subscriptionData?.maxTokens;
			usage = usage.toFixed(2);
			let isExceded = false;
			if (userData?.subscriptionData?.maxTokens !== undefined) {
				isExceded = userData.monthUsage.count >= userData?.subscriptionData?.maxTokens
			}
			return <Box className={classNames(styles.usageCont, {
				[styles.usageContExceeded]: isExceded,
			})}>
				<Heading className={styles.usagePlan} size="p" color="#1A202C">{(userData?.subscriptionData?.name || '').toLowerCase().replace('app sumo', 'LTD')} plan</Heading>
				<Box className={styles.usgaeNumbers}><Text as="span" fontWeight="bold">{formatNumber(userData.monthUsage.count)}</Text>/<span style={{ color: "#6C757D" }}>{formatNumber(userData?.subscriptionData?.maxTokens)}</span></Box>
				<Text className={styles.usageLabel}>Monthly usage limits</Text>
				<Box position="relative" display="inline-block" width="100%" marginTop="15px">
					<Progress value={usage} colorScheme={isExceded ? 'red' : 'purple'} borderRadius="2px" className={styles.progressbar} />
					<Box
						position="absolute"
						top="0"
						left="0"
						height="100%"
						display="flex"
						alignItems="center"
						justifyContent="center"
						fontSize="10px"
						fontWeight="500"
						color={usage >= 20 ? "#fff" : "#000"}
						w={usage >= 20 ? `${usage}%` : `${60}%`}
					>
						{isNaN(usage) ? 0 : usage}%
					</Box>
				</Box>

				{
					(userData?.subscriptionData?.type !== 'LIFETIME') ? (<Box w="100%" className={styles.usageUpgradeBtn} mt="20px">
						<Link to="/app/subscription/">
							<Button
								w="100%"
								colorScheme="gray"
								variant="solid"
								size="sm"
							>
								Upgrade now
							</Button>
						</Link>
					</Box>) : null
				}

			</Box>
		} catch (error) {

			return null
		}

	}, [userData]);

	const logOut = () => {
		props.onLoginOut();
		history.push('/login');
	};
	return (
		<VStack spacing="0" w="100%" h="100vh">
			<Flex
				shrink={0}
				shadow="base"
				w="100%"
				h="60px"
				bg="white"
				justifyContent="space-between"
				pr="10"
				pb="4"
				pt="4"
				pl="10"
				zIndex="2"
				alignItems="center"
			>
				<Link to="/" style={{ textDecoration: "none" }}><Logo></Logo></Link>
				<Box>
					<Menu>
						<MenuButton>
							{/* <Avatar size="sm" bg="teal.500" /> */}
							<Image src={userData?.avatarUrl} height="24px" width="24px" borderRadius="999px" />
						</MenuButton>
						<MenuList minW="180px">
							<MenuItem fontSize="small">
								{/* <Avatar mr={2} size="sm" bg="teal.500" /> */}
								<Image src={userData?.avatarUrl} height="40px" width="40px" borderRadius="999px" mr={2} />
								{userData?.email}
							</MenuItem>
							<MenuDivider />
							<Link to="/app/myprofile">
								<MenuItem fontSize="small">
									<Image src={profileIcon} alt="profle-icon" height="17" style={{ marginRight: "12px" }} />
									My Profile</MenuItem>
							</Link>

							<MenuItem onClick={logOut} fontSize="small">
								<Image src={logoutIcon} alt="Log-Out-icon" height="17" style={{ marginRight: "12px" }} />
								Log Out
							</MenuItem>
						</MenuList>
					</Menu>
				</Box>
			</Flex>
			<Flex flex={1} h="calc(100% - 100px)" w="100%">
				<HStack spacing="0" w="100%" justify="start">
					<Flex
						h="100%"
						w="260px"
						shadow="base"
						bg="white"
						flexShrink={0}
						p="10"
						direction="column"
						justifyContent="space-between"
						pt="10"
					>
						<List spacing={7}>
							<ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/chat-bots">
									<Flex alignItems="center">
										<Image src={ProjectIcon} alt="Projects-icon" height="24px" width="24px" style={{ marginRight: "10px" }} />
										<Text as="span" fontSize="13px">Projects</Text>
									</Flex>
								</NavLink>
							</ListItem>

							<ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/subscription">
									<Flex alignItems="center">
										<Image src={SubscriptionIcon} alt="Subscriptions-icon" height="24px" width="24px" style={{ marginRight: "10px" }} />
										<Text as="span" fontSize="13px">Subscriptions</Text>
									</Flex>
								</NavLink>
							</ListItem>
							{userData?.role === "admin" ? <ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/users">
									<Flex alignItems="center">
										<Image src={UserIcon} alt="Platform-Users-icon" height="24px" width="24px" style={{ marginRight: "10px" }} />
										<Text as="span" fontSize="13px">Platform Users</Text>
									</Flex>
								</NavLink>
							</ListItem> : null}
							{userData?.role === "admin" ? <ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/inviteUsers">
									<Flex alignItems="center">
										<Image src={LeadsDataiIcon} alt="Scraped-Leads-Data-icon" height="24px" width="24px" style={{ marginRight: "10px" }} />
										<Text as="span" fontSize="13px">Scraped Leads Data</Text>
									</Flex>
								</NavLink>
							</ListItem> : null}
							{userData?.role === "admin" ? <ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/email-templates">
									<Flex alignItems="center">
										<HiOutlineMailOpen size={24} style={{ marginRight: '10px' }} />
										<Text as="span" fontSize="13px">Email Templates</Text>
									</Flex>
								</NavLink>
							</ListItem> : null}
							{userData?.role === "admin" ? <ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink activeClassName={styles.activeNav} to="/app/edit-chatbot/6740784777f34c18ad3ad90e/">
									<Flex alignItems="center">
										<HiOutlineMailOpen size={24} />
										<span style={{ marginLeft: '8px' }}>Vakmangids</span>
									</Flex>
								</NavLink>
							</ListItem> : null}
							{userData?.role === "user" ? <ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink
									to="/app/email-campaigns"
									activeClassName={styles.activeNav}
									isActive={(match, location) =>
										!!match || location.pathname === "/app/leadsHistory"
									}
								>
									<Flex alignItems="center">
										<Image src={EmailCampaignsIcon} alt="Email-Campaigns-icon" height="24px" width="24px" style={{ marginRight: "10px" }} />
										<Text as="span" fontSize="13px">Ultimamail Campaigns</Text>
									</Flex>
								</NavLink>
							</ListItem> : null}
							{userData?.role === "user" ? <ListItem display="flex" alignItems="center" fontSize="sm">
								<NavLink
									to="/app/ultimamailsettings"
									activeClassName={styles.activeNav}
									isActive={(match, location) =>
										!!match || location.pathname === "/app/ultimamailSettingsHistory" || location.pathname.startsWith("/app/settingDetails/")
									}
								>
									<Flex alignItems="center">
										<Image src={UltimamailSettingsIcon} alt="UltimamailSettingsIcon-icon" height="24px" width="24px" style={{ marginRight: "10px" }} />
										<Text as="span" fontSize="13px">Ultimamail Settings</Text>
									</Flex>
								</NavLink>
							</ListItem> : null}
						</List>
						{getUsageComponent()}
					</Flex>
					{userData && userData.role === "user" && (
						<Switch>
							<Route exact path="/app/chat-bots">
								<ChatbotList />
							</Route>
							<Route exact path="/app/subscription">
								<Settings />
							</Route>
							<Route exact path="/app/myprofile">
								<MyProfile />
							</Route>
							<Route exact path="/app/email-campaigns">
								<EmailCampaignsAdmin />
							</Route>
							<Route exact path="/app/leadsHistory">
								<LeadsHistory />
							</Route>
							<Route exact path="/app/ultimamailsettings">
								<UltimamailSettings />
							</Route>
							<Route exact path="/app/ultimamailSettingsHistory">
								<UltimamailSettingsHistory />
							</Route>
							<Route exact path="/app/settingDetails/:id">
								<SettingDetails />
							</Route>
							<PrivateRoute
								isAuthenticated={isAuthenticated}
								exact
								path={editChatbotPath}
								component={EditChatbot}
							/>
							<ChatbotList />
						</Switch>
					)}

					{userData && userData.role === "admin" && (
						<Switch>
							<Route exact path="/app/chat-bots">
								<ChatbotList />
							</Route>
							<Route exact path="/app/subscription">
								<Settings />
							</Route>
							<Route exact path="/app/chat-bots-demo">
								<DemoChatbots />
							</Route>
							<Route exact path="/app/inviteUsers">
								<InviteUsers />
							</Route>
							<Route exact path="/app/email-templates">
								<EmailTemplateSuperadmin />
							</Route>
							<Route exact path="/app/inviteUsers/usersHistory">
								<InviteUsersHistory />
							</Route>
							<Route exact path="/app/myprofile">
								<MyProfile />
							</Route>
							<Route exact path="/app/users">
								<Users />
							</Route>
							<Route exact path="/app/users/profile/:userId">
								<UserProfile />
							</Route>
							<PrivateRoute
								isAuthenticated={isAuthenticated}
								exact
								path={editChatbotPath}
								component={EditChatbot}
							/>
							<ChatbotList />
						</Switch>
					)}
				</HStack>
			</Flex>
		</VStack>
	);
};
