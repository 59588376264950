import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Heading,
  Text,
  Select,
  InputGroup,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Checkbox,
  useToast,
  FormControl, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tag,
  TagLabel,
  FormLabel,
  NumberInput,
  NumberInputField,
  Image,
  FormErrorMessage,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Spinner,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { GoInfo } from "react-icons/go";
import styles from "../EmailCampaignsAdmin.module.scss";
import DeleteIconRed from "./images/deleteIcon.svg"
import ErrorImg from "./images/error.svg"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { fetchKnowledgebases } from "../../../services/knowledgebaseService";
import { getCampaignDetails, getEmailsByUserId, triggerCampaign, updateCampaign } from "../../../services/emailCampaigns";
import { Link, useHistory } from 'react-router-dom';
import saveIcon from "./images/saveBtn.svg"

const instruction = (
  <>
    Generate your passkey using the link below:{" "}
    <Link
      to="//support.google.com/accounts/answer/13548313?hl=en"
      isExternal
      target="_blank"
      rel="noopener noreferrer"
      color="blue.500"
      textDecoration="underline"
    >
      Link here
    </Link>
  </>
);
export const ModifyYourCampaignForm = ({ campaignId, ModifyYourCampaignClose, SuccessfullyModalOpen, fetchData, currentPage, itemsPerPages }) => {

  const [painPoints, setPainPoints] = React.useState([{ value: '' }]);
  const [solutionOffer, setSolutionOffer] = React.useState([{ value: '' }]);
  const [emailAddress, setEmailAddress] = useState(''); // State for email address
  const [campaignName, setCampaignName] = React.useState('');
  // const [emailAndSenderLimit, setEmailFields] = React.useState([{ email: '', maxLimit: 100 }]);
  const [isOpens, setIsOpens] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [selectedCTAs, setSelectedCTAs] = useState([]);// State for multiple CTA selections
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [emails, setemails] = useState([]);
  const toast = useToast();
  const history = useHistory();


  const { register, handleSubmit, formState: { errors }, setError, clearErrors, control, setValue, watch, reset } = useForm({
    defaultValues: {
      targetAudience: [{ value: '' }],
      painPoints: [{ value: '' }],
      solutionOffer: [{ value: '' }],
      emailAndSenderLimit: [{ email: '', maxLimit: 1 }],
      campaignProject: null
    },
  });

  const maxEmailsPerDay = watch("maxEmailsPerDay");
  const maxEmailsPerLead = watch("maxEmailsPerLead");
  const emailAndSenderLimit = watch("emailAndSenderLimit")

  const onSubmit = async (formData) => {
    // Check if at least one CTA is selected
    if (selectedCTAs.length === 0) {
      setError("selectedCTAs", {
        type: "manual",
        message: "Please select at least one CTA"
      });
      return; // Prevent form submission
    }
    const callOfAction = {};
    if (formData.emailAddress) {
      callOfAction.email = formData.emailAddress;
    }
    if (formData.phoneNumber) {
      callOfAction.phone = formData.phoneNumber;
    }
    if (formData.customLink) {
      callOfAction.customLink = formData.customLink;
    }

    // Remove individual fields from formData
    delete formData.emailAddress;
    delete formData.phoneNumber;
    delete formData.customLink;

    // Append callOfAction object to formData
    formData.callOfAction = callOfAction;
    formData.maxEmailsPerDay = parseInt(formData.maxEmailsPerDay);
    formData.maxEmailsPerLead = parseInt(formData.maxEmailsPerLead);
    formData.campaignId = campaignId
    // Encrypting passKeys in emailAndSenderLimit
    const encryptedData = {
      ...formData,
      emailAndSenderLimit: formData.emailAndSenderLimit.map(item => ({
        ...item,

      }))
    };
    const triggerCampaignId = { campaignId: campaignId }
    try {
      setIsLoading(true);
      const response = await updateCampaign(encryptedData); // Replace with your API endpoint
      if (response?.data?.statusCode === 200) {
        const responses = await triggerCampaign(triggerCampaignId);
        reset();
        fetchData(currentPage, itemsPerPages, '');
        SuccessfullyModalOpen();
        ModifyYourCampaignClose();
        setTimeout(() => {
          toast({
            title: `${responses?.data?.message}`,
            status: "success",
            isClosable: true,
          })
        }, 2000)
        toast({
          title: `${response?.data?.message}`,
          status: "success",
          isClosable: true,
        })
        history.push(`/app/email-campaigns`);
      }
      // Assuming `response` is an array of project objects
    } catch (error) {
      // console.error("Error fetching projects:", error);
      toast({
        title: `${error?.response?.data?.message}`,
        status: "error",
        isClosable: true,
      })
    } finally {
      setIsLoading(false);
    }
  };

  const {
    fields: targetAudienceFields,
    append: appendTargetAudience,
    remove: removeTargetAudience,
    replace: replaceTargetAudience
  } = useFieldArray({
    control,
    name: 'targetAudience',
  });

  const {
    fields: painPointsFields,
    append: appendPainPoints,
    remove: removePainPoints,
    replace: replacePainPoints
  } = useFieldArray({
    control,
    name: 'painPoints',
  });

  const {
    fields: solutionOfferFields,
    append: appendSolutionOffer,
    remove: removeSolutionOffer,
    replace: replaceSolutionOffer
  } = useFieldArray({
    control,
    name: 'solutionOffer',
  });

  const {
    fields: emailAndSenderLimitFields,
    append: appendEmailAndSenderLimit,
    remove: removeEmailAndSenderLimit,
    replace: replaceEmailAndSenderLimit
  } = useFieldArray({
    control,
    name: 'emailAndSenderLimit',
  });

  const handleRemoveField = (index, fieldArrayType) => {
    if (fieldArrayType === 'targetAudience') {
      removeTargetAudience(index);
    } else if (fieldArrayType === 'painPoints') {
      removePainPoints(index);
    } else if (fieldArrayType === 'solutionOffer') {
      removeSolutionOffer(index);
    } else if (fieldArrayType === 'emailAndSenderLimit') {
      removeEmailAndSenderLimit(index);
    }
  };
  // Fetch projects from API
  React.useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetchKnowledgebases(); // Replace with your API endpoint
        setProjects(response.data); // Assuming `response` is an array of project objects
        const responses = await getEmailsByUserId(); // Replace with your API endpoint
        setemails(responses?.data?.data); // Assuming `response` is an array of project objects
        const updatedData = responses?.data?.data?.map((item) => ({
          ...item,
          // status: "inactive",
        }));
        setemails(updatedData); // Assuming `response` is an array of project objects

      } catch (error) {
        // console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, []);

  React.useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {

        const response = await getCampaignDetails(campaignId);
        const campaignData = response.data.data;

        // Set form values
        setValue('campaignName', campaignData.campaignName);
        if (campaignData?.campaignProject?.projectId) {
          const projectToSelect = projects.find(
            project => project._id === campaignData.campaignProject.projectId
          );

          if (projectToSelect) {
            setValue('campaignProject', {
              projectId: projectToSelect._id,
              projectName: projectToSelect.name
            }, {
              shouldValidate: true,
              shouldDirty: true
            });
          }
        }
        setValue('campaignLanguage', campaignData.campaignLanguage);
        setValue('emailLength', campaignData.emailLength);
        setValue('maxEmailsPerDay', campaignData.maxEmailsPerDay);
        setValue('maxEmailsPerLead', campaignData.maxEmailsPerLead);

        // Handle arrays with useFieldArray
        if (campaignData.targetAudience) {
          // Assuming campaignData.targetAudience is an array of objects like { value: 'some value' }
          const updatedTargetAudience = campaignData.targetAudience.map((item) => ({ value: item.value || item }));

          // Replace the target audience fields with the new data
          replaceTargetAudience(updatedTargetAudience);

          // Set the form values manually to ensure the input fields are filled correctly
          updatedTargetAudience.forEach((item, index) => {
            setValue(`targetAudience[${index}].value`, item.value); // This ensures that only the 'value' is used
          });
        }
        if (campaignData.painPoints) {
          const updatedPainPoints = campaignData.painPoints.map((item) => ({ value: item.value || item }));
          replacePainPoints(updatedPainPoints);
          updatedPainPoints.forEach((item, index) => {
            setValue(`painPoints[${index}].value`, item.value); // This ensures that only the 'value' is used
          });;
        }
        if (campaignData.solutionOffer) {
          const updatedSolutionOffer = campaignData.solutionOffer.map((item) => ({ value: item.value || item }));
          replaceSolutionOffer(updatedSolutionOffer);
          updatedSolutionOffer.forEach((item, index) => {
            setValue(`solutionOffer[${index}].value`, item.value); // This ensures that only the 'value' is used
          });;
        }
        if (campaignData.emailAndSenderLimit) {
          const updatedEmailAndSenderLimit = Array.isArray(campaignData.emailAndSenderLimit)
            ? campaignData.emailAndSenderLimit.map((item) => ({
              email: item?.email || '',          // Ensure correct mapping of `email`
              maxLimit: item?.maxLimit || 1,     // Ensure correct mapping of `maxLimit`
              // passKey: item?.passKey ? decryptPasskey(item.passKey) : "" // Decrypt passKey if exists
            }))
            : [{
              email: campaignData.emailAndSenderLimit.email || '',
              maxLimit: campaignData.emailAndSenderLimit.maxLimit || 1,
            }];

          replaceEmailAndSenderLimit(updatedEmailAndSenderLimit);

          // Set individual values for each emailAndSenderLimit entry
          updatedEmailAndSenderLimit.forEach((item, index) => {

            updatedEmailAndSenderLimit.forEach((item, index) => {
              if (item.email) {
                setValue(`emailAndSenderLimit[${index}].email`, item.email);
                setemails((prevEmails) =>
                  prevEmails.map((emailObj) =>
                    emailObj.email === item.email
                      ? { ...emailObj, status: "active" } // Update the status of the selected email
                      : emailObj // Leave other email objects unchanged
                  )
                );
              }
              setValue(`emailAndSenderLimit[${index}].maxLimit`, item.maxLimit); // Set maxLimit
            });
          });
        }
        // Handle CTAs
        if (campaignData.callOfAction) {
          const ctas = [];
          if (campaignData.callOfAction.email) {
            ctas.push('Email');
            setValue('emailAddress', campaignData.callOfAction.email);
          }
          if (campaignData.callOfAction.phone) {
            ctas.push('Phone Number');
            setValue('phoneNumber', campaignData.callOfAction.phone);
          }
          if (campaignData.callOfAction.customLink) {
            ctas.push('Custom Links');
            setValue('customLink', campaignData.callOfAction.customLink);
          }
          setSelectedCTAs(ctas);
        }

      } catch (error) {
        // console.error("Error fetching campaign details:", error);
        toast({
          title: "Error fetching campaign details",
          status: "error",
          isClosable: true,
        });
      } finally {
        setIsLoadingData(false);
      }
    };

    if (campaignId && projects.length > 0) {
      fetchCampaignDetails();
    }
  }, [campaignId, projects, setValue]);




  const handleSelectCTA = (cta) => {
    setSelectedCTAs((prev) =>
      prev.includes(cta) ? prev.filter((item) => item !== cta) : [...prev, cta]
    );
    if (selectedCTAs.length > 0) {
      clearErrors("selectedCTAs");
    }
  };



  const isCloseModifyYourCampaign = () => {
    setIsOpen(true)
    // ModifyYourCampaignClose();
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onClosePause = () => {
    setIsOpen(false)
  }

  const handleNoClick = () => {
    ModifyYourCampaignClose();
    setIsOpen(false);
    history.push(`/app/email-campaigns`);


  }
  return (
    <>

      {isLoadingData ? <>
        <Spinner color='gray.700' m={'25vh auto'} d={'block'} />
      </> :

        <form onSubmit={handleSubmit(onSubmit)}>
          <Text textAlign="center" mb="32px" fontSize="14px" fontWeight="400" color="#6C757D">
            Enter the following details to modify your campaign.
          </Text>

          {/* Campaign Name */}
          <FormControl mb="26px" isInvalid={!!errors.campaignName}>
            <FormLabel>Campaign Name <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
            <Input placeholder="Enter campaign name" {...register("campaignName", { required: "Campaign Name is required" })} />
            {errors.campaignName && <FormErrorMessage>{errors.campaignName.message}</FormErrorMessage>}
          </FormControl>

          {/* Campaign Project */}
          <FormControl mb="32px" isInvalid={!!errors.campaignProject}>
            <FormLabel>Campaign Project <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
            <Controller
              name="campaignProject"
              control={control}
              rules={{ required: "Campaign Project is required" }}
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Please select the project/website URL for this campaign"
                  value={value?.projectId || ""}
                  onChange={(e) => {
                    const selectedProject = projects.find(
                      (project) => project._id === e.target.value
                    );
                    onChange(
                      selectedProject
                        ? {
                          projectId: selectedProject._id,
                          projectName: selectedProject.name
                        }
                        : null
                    );
                  }}
                >
                  {projects.map((project) => (
                    <option key={project._id} value={project._id}>
                      {project.name}
                    </option>
                  ))}
                </Select>
              )}
            />
            {errors.campaignProject && <FormErrorMessage>{errors.campaignProject.message}</FormErrorMessage>}
          </FormControl>

          {/* Target Audience */}
          <FormControl mb="16px" isInvalid={!!errors.targetAudience}>
            <FormLabel>Target Audience <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
            {targetAudienceFields.map((field, index) => (
              <FormControl
                key={index}
                isInvalid={errors.targetAudience?.[index]?.value}
                mb="12px"
              >
                <Box display="flex" alignItems="center" gap="10px">
                  <Input
                    placeholder="Enter target audience"
                    {...register(`targetAudience[${index}].value`, { required: "Target Audience is required" })}
                  />

                  <Button
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => handleRemoveField(index, 'targetAudience')}
                    disabled={targetAudienceFields.length === 1}
                  >
                    <Image src={DeleteIconRed} alt="deleteicon" />
                  </Button>
                </Box>
                {errors.targetAudience?.[index]?.value && (
                  <FormErrorMessage>{errors.targetAudience[index].value.message}</FormErrorMessage>
                )}
              </FormControl>
            ))}
            {targetAudienceFields.length < 5 && <Button size="sm" onClick={() => appendTargetAudience({ value: '' })}>+ Add</Button>}
          </FormControl>


          {/* Pain Points */}
          <FormControl mb="16px" isInvalid={!!errors.painPoints}>
            <FormLabel>Pain Points <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
            {painPointsFields.map((field, index) => (
              <FormControl
                key={index}
                isInvalid={errors.painPoints?.[index]?.value}
                mb="12px"
              >
                <Box display="flex" alignItems="center" gap="10px">
                  <Input
                    placeholder="Enter pain points"
                    {...register(`painPoints[${index}].value`, { required: "Pain Points are required" })}
                  />

                  <Button
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => handleRemoveField(index, 'painPoints')}
                    disabled={painPointsFields.length === 1}
                  >
                    <Image src={DeleteIconRed} alt="deleteicon" />
                  </Button>
                </Box>
                {errors.painPoints?.[index]?.value && (
                  <FormErrorMessage>{errors.painPoints[index].value.message}</FormErrorMessage>
                )}
              </FormControl>
            ))}
            {painPointsFields.length < 5 && <Button size="sm" onClick={() => appendPainPoints({ value: '' })}>+ Add</Button>}
          </FormControl>

          {/* Solution/Offer */}
          <FormControl mb="16px" isInvalid={!!errors.solutionOffer}>
            <FormLabel>Solution/Offer <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
            {solutionOfferFields.map((field, index) => (
              <FormControl
                key={index}
                isInvalid={errors.solutionOffer?.[index]?.value}
                mb="12px"
              >
                <Box display="flex" alignItems="center" gap="10px">
                  <Input
                    placeholder="Enter the solution/offer"
                    {...register(`solutionOffer[${index}].value`, { required: "Solution/Offer is required" })}
                  />

                  <Button
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => handleRemoveField(index, 'solutionOffer')}
                    disabled={solutionOfferFields.length === 1}
                  >
                    <Image src={DeleteIconRed} alt="deleteicon" />
                  </Button>
                </Box>
                {errors.solutionOffer?.[index]?.value && (
                  <FormErrorMessage>{errors.solutionOffer[index].value.message}</FormErrorMessage>
                )}
              </FormControl>
            ))}
            {solutionOfferFields.length < 5 && <Button size="sm" onClick={() => appendSolutionOffer({ value: '' })}>+ Add</Button>}
          </FormControl>


          {/* Call To Action (CTA) */}
          <FormControl mb="16px" isInvalid={!!errors.selectedCTAs}>
            <FormLabel>Select Call To Action (CTA) <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                width="100%"
                textAlign="left"
                minHeight="38px"
                h="auto"
                border="1px solid #E2E8F0"
                bg="#fff"
                sx={{
                  _hover: { backgroundColor: "#fff" },
                  _active: { backgroundColor: "#fff", boxShadow: "none" },
                  _focus: { boxShadow: "none" },
                }}
              >
                {selectedCTAs.length > 0 ? (
                  <HStack spacing={2} wrap="wrap" maxHeight="60px" overflowY="auto" gap="5px" mt="5px" mb="5px">
                    {selectedCTAs.map((cta) => (
                      <Tag key={cta} size="sm" borderRadius="full" variant="solid" colorScheme="blue" marginInlineStart="0px !important">
                        <TagLabel>{cta}</TagLabel>
                      </Tag>
                    ))}
                  </HStack>
                ) : (
                  <Text as="span" className={styles.newCampaigPara}>Select CTAs</Text>
                )}
              </MenuButton>
              <MenuList>
                {["Email", "Phone Number", "Custom Links"].map((cta) => (
                  <MenuItem key={cta} onClick={() => handleSelectCTA(cta)} display="flex" justifyContent="space-between">
                    <Checkbox
                      isChecked={selectedCTAs.includes(cta)}
                      onChange={() => handleSelectCTA(cta)}
                    >
                      {cta}
                    </Checkbox>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            {errors.selectedCTAs && (
              <FormErrorMessage>{errors.selectedCTAs.message}</FormErrorMessage>
            )}
          </FormControl>

          {/* Render input fields based on selected CTAs */}
          {selectedCTAs.includes("Email") && (
            <FormControl mb="16px" isInvalid={!!errors.emailAddress}>
              <Input
                // mt="4"
                placeholder="Enter Email Address"
                {...register("emailAddress", {
                  required: "Email address is required",
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter a valid email address",
                  },
                })}
              />
              <FormErrorMessage>{errors.emailAddress?.message}</FormErrorMessage>
            </FormControl>
          )}

          {selectedCTAs.includes("Phone Number") && (
            <FormControl mb="16px" isInvalid={!!errors.phoneNumber}>
              <InputGroup mt="4">
                <Input
                  type="tel"
                  placeholder="Phone Number"
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Enter a valid 10-digit phone number",
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
            </FormControl>
          )}

          {selectedCTAs.includes("Custom Links") && (
            <FormControl mb="16px" isInvalid={!!errors.customLink}>
              <Input
                // mt="4"
                placeholder="Enter Custom Link"
                {...register("customLink", {
                  required: "Custom link is required",
                  pattern: {
                    value: /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/.*)?$/,
                    message: "Enter a valid URL",
                  },
                })}
              />
              <FormErrorMessage>{errors.customLink?.message}</FormErrorMessage>
            </FormControl>
          )}


          {/* Language of the Campaign */}
          <FormControl mb="16px" isInvalid={!!errors.campaignLanguage}>
            <FormLabel>Language of the Campaign <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
            <Controller
              name="campaignLanguage"
              control={control}
              rules={{ required: "Campaign Language is required" }}
              render={({ field }) => (
                <Select placeholder="Select campaign language" {...field}>
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                  <option value="French">French</option>
                  <option value="German">German</option>
                </Select>
              )}
            />
            {errors.campaignLanguage && <FormErrorMessage>{errors.campaignLanguage.message}</FormErrorMessage>}
          </FormControl>

          {/* Email Length */}
          <FormControl mb="16px" isInvalid={!!errors.emailLength}>
            <FormLabel>Email Length <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
            <Controller
              name="emailLength"
              control={control}
              rules={{ required: "Email Length is required" }}
              render={({ field }) => (
                <Select placeholder="Select email length" {...field}>
                  <option value="Short (Upto 500 words)">Short (Upto 500 words)</option>
                  <option value="Medium (Upto 750 words)">Medium (Upto 750 words)</option>
                  <option value="Long (Upto 1000 words)">Long (Upto 1000 words)</option>
                </Select>
              )}
            />
            {errors.emailLength && <FormErrorMessage>{errors.emailLength.message}</FormErrorMessage>}
          </FormControl>

          {/* Max Emails Per Day */}
          <HStack spacing={4} mb="32px">
            <FormControl isInvalid={!!errors.maxEmailsPerDay}>
              <FormLabel>Max Emails Per Day <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
              <NumberInput step={1} min={1} value={maxEmailsPerDay || ""}
                onChange={(valueString) => setValue("maxEmailsPerDay", parseInt(valueString, 10))}
              >
                <NumberInputField
                  {...register("maxEmailsPerDay", { required: "Max Emails Per Day is required" })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {errors.maxEmailsPerDay && <FormErrorMessage>{errors.maxEmailsPerDay.message}</FormErrorMessage>}
            </FormControl>

            {/* Max Emails Per Lead */}
            <FormControl isInvalid={!!errors.maxEmailsPerLead}>
              <FormLabel>Max Emails Per Lead <Text as="span" fontSize="10px">(Auto Reply)</Text><Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></FormLabel>
              <NumberInput defaultValue={100} step={1} min={1}
                value={maxEmailsPerLead || ""}
                onChange={(valueString) => setValue("maxEmailsPerLead", parseInt(valueString, 10))}
              >
                <NumberInputField
                  {...register("maxEmailsPerLead", { required: "Max Emails Per Lead is required" })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {errors.maxEmailsPerLead && <FormErrorMessage>{errors.maxEmailsPerLead.message}</FormErrorMessage>}
            </FormControl>
          </HStack>
          {/* Emails for this campaign */}
          <HStack spacing={1} mb="8px" >
            <Heading as="h3" size="sm" mb="8px">Emails for this campaign <Text as={"span"} fontSize="16px" fontWeight={"400"} style={{ color: "#DC3545" }}>*</Text></Heading>
          </HStack>
          <Box mb="24px">
            {emailAndSenderLimitFields.map((field, index) => (
              <React.Fragment key={field.id}>
                <HStack mb="8px" width="100%" spacing="12px" alignItems="flex-start">
                  <FormControl isInvalid={!!errors?.emailAndSenderLimit?.[index]?.email}>
                    <FormLabel>Email {index + 1}</FormLabel>
                    <Controller
                      name={`emailAndSenderLimit[${index}].email`}
                      control={control}
                      rules={{ required: "Please enter a valid email" }}
                      render={({ field: controllerField }) => (
                        <Select
                          placeholder="Please select the email"
                          value={controllerField.value}
                          onChange={(e) => {
                            const selectedTargetValue = e.target.value;
                            const selectedProject = emails.find(
                              (project) => project.email === selectedTargetValue
                            );

                            // Update email statuses
                            setemails((prevEmails) =>
                              prevEmails.map((emailObj) => ({
                                ...emailObj,
                                status: emailObj.email === selectedTargetValue
                                  ? "active"
                                  : emailObj.email === controllerField.value
                                    ? "inactive"
                                    : emailObj.status
                              }))
                            );

                            // Update the controller field
                            controllerField.onChange(
                              selectedProject?.email ? selectedProject.email : null
                            );
                          }}
                        >
                          {emails.map((project) => (
                            <option
                              key={project.email}
                              value={project.email}
                              disabled={project.status === "active"}
                            >
                              {project.email}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    {errors?.emailAndSenderLimit?.[index]?.email && (
                      <FormErrorMessage>
                        {errors.emailAndSenderLimit[index].email.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors?.emailAndSenderLimit?.[index]?.maxLimit}>
                    <FormLabel>Maximum limit</FormLabel>
                    <NumberInput defaultValue={2} step={1} min={1}>
                      <NumberInputField
                        {...register(`emailAndSenderLimit[${index}].maxLimit`, {
                          required: "Max Limit is required",
                        })}
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    {errors?.emailAndSenderLimit?.[index]?.maxLimit && (
                      <FormErrorMessage>
                        {errors.emailAndSenderLimit[index].maxLimit.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <Button
                    colorScheme="red"
                    variant="ghost"
                    disabled={emailAndSenderLimitFields.length === 1}
                    onClick={() => {
                      // Get the email value before removing the field
                      const emailToRemove = watch(`emailAndSenderLimit[${index}].email`);
                      // if (!emailToRemove) {
                      //   console.error(`Email not found at index: ${index}`);
                      //   return;
                      // }
                      // Reset the status of the email being removed
                      setemails((prevEmails) =>
                        prevEmails.map((emailObj) =>
                          emailObj.email === emailToRemove
                            ? { ...emailObj, status: "inactive" }
                            : emailObj
                        )
                      );

                      // Remove the field
                      handleRemoveField(index, 'emailAndSenderLimit');
                    }}
                    sx={{
                      _focus: {
                        boxShadow: 'none',
                      },
                      marginTop: "25px !important"
                    }}
                  >
                    <Image src={DeleteIconRed} alt='deleteicon' h="35px" />
                  </Button>
                </HStack>
              </React.Fragment>
            ))}
            {emailAndSenderLimitFields.length < 5 && (
              <Button
                size="sm"
                onClick={() => appendEmailAndSenderLimit({ email: "", maxLimit: "100" })}
              >
                + Add
              </Button>
            )}
          </Box>
          <ModalFooter>
            <Button mr={3}
              onClick={isCloseModifyYourCampaign}

            >Cancel</Button>
            <Button colorScheme="blue" type="submit" disabled={isLoading}>
              {isLoading ? <Spinner color='gray.700' />
                :
                <>
                  <Image src={saveIcon} alt='Save & Continue' /><Text as="span" fontSize="16px" ms="8px" fontWeight="400">Save & Trigger</Text>
                </>
              }

            </Button>
          </ModalFooter>
        </form>
      }
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalBody>
            <Box
              textAlign="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Image src={ErrorImg} alt='deleteicon' w="56px" h="56px" />
              <Text as="p" fontSize="18px" fontWeight="700" color="#1A202C" mb="8px" mt="24px">Are you Cancel?</Text>
              <Text as="p" fontSize="14px" fontWeight="400" color="#6C757D">You want to Pause this campaign?</Text>
            </Box>

          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={handleNoClick} style={{ backgroundColor: "#6C757D", color: "#fff" }} fontSize="16px" fontWeight="400" sx={{
              _focus: {
                boxShadow: 'none',  // Removes the focus box shadow
              }
            }}>
              Yes
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClosePause} sx={{
              _focus: {
                boxShadow: 'none',  // Removes the focus box shadow
              }
            }}
            >
              <Text as="span" ms="4px" fontSize="16px" fontWeight="400">No</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
