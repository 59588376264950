import * as React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Th,
  Select,
  TableContainer,
  InputGroup,
  InputLeftElement,
  Input,
  useToast,
  Image
} from "@chakra-ui/react";
import styles from "./EmailCampaignsAdmin.module.scss";
import { IoIosSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import { DetailsOfCampaignModal } from "./Modal/DetailsOfCampaignModal";
import { ReadIcon } from "../../components/Icons/ReadIcon";
import { CreateNewCampaignModal } from "./Modal/CreateNewCampaignModal";
import { campaignGetAllCampaign } from "../../services/emailCampaigns";
import { PaginatorTwo } from "../../widgets/Paginator/PaginatorTwo";
import { useLocation } from 'react-router-dom';
import { AddTargetAudience } from "./Modal/AddTargetAudience";
import { useHistory } from 'react-router-dom';
import CreateCampaign from "./Modal/images/createCampaign.svg";
import ScrapedHistory from "./scrapedHistory.svg";

export const EmailCampaignsAdmin = () => {
  const toast = useToast();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [itemsPerPages, setItemsPerPage] = React.useState<number>(10);
  const [searchValue, setSearchValue] = React.useState('');
  const [inviteUsersData, setInviteUsersData] = React.useState<any>();
  const [isOpens, setIsOpens] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState(searchValue);
  const [isSearchActive, setIsSearchActive] = React.useState<boolean>(false);
  const [forcePage, setForcePage] = React.useState<number>(0);
  const [isOpenTargetAudience, setIsOpenTargetAudience] = React.useState<boolean>(false);
  const [isCampaignTrigger, setIsCampaignTrigger] = React.useState<boolean>(false);
  const [campaignId, setCampaignId] = React.useState<any>(null);
  const [campaignName, setCampaignName] = React.useState<any>(null);
  const [isReviewOpen, setIsReviewOpen] = React.useState<boolean>(false)
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const cId = searchParams.get('campaignId'); // Replace 'pid' with your parameter key if needed
    setCampaignId(cId);
    const cName = searchParams.get('campaignName'); // Another parameter
    setCampaignName(cName);

    // Apply a condition based on the parameter's existence
    if (cId && cName) {
      setIsOpenTargetAudience(true);
    }
  }, [inviteUsersData]);

  const onCloseTargetAudience = () => {
    setIsOpenTargetAudience(false);
  };

  const handlePageChange = (page: number) => {
    let pages: number = page + 1;
    setCurrentPage(pages);
    setForcePage(page);
  };
  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
    setForcePage(0);
  };


  const onCloses = () => {
    setIsOpens(false);
  };





  const handleURLClick = React.useCallback(async (data) => {
    setModalData(data);
    setIsOpens(true);
  }, [isOpens]);




  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value); // Update state with the input value
  };

  const onClose = () => {
    // Clear fields if necessar
    setIsOpen(false);

  };


  const fetchData = async (page: number, pageSize: number, searchKeyword: string) => {

    try {
      const response: any = await campaignGetAllCampaign(pageSize, page, searchKeyword);
      setInviteUsersData(response);

    } catch (error: any) {

    }
  };


  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);

      if (searchValue !== "") {
        setIsSearchActive(true);
        setCurrentPage(1);
        setForcePage(0);
      } else {
        setIsSearchActive(false);
      }
    }, 1500);

    return () => clearTimeout(handler);
  }, [searchValue]);

  React.useEffect(() => {
    fetchData(currentPage, itemsPerPages, debouncedSearchValue);
  }, [debouncedSearchValue, itemsPerPages, currentPage]);


  return (
    <Box
      w="100%"
      p="6"
      shadow="sm"
      h="100%"
      position="relative"
      overflow="auto"
    >
      <VStack w="100%" spacing="10">
        <Flex
          shrink={0}
          w="100%"
          direction="column"
          justifyContent="space-between"
        >
          <Flex width="100%" mb="8" justifyContent="space-between">
            <Box>
              <Heading fontSize="30px" color="#1A202C">Ultimamail Campaigns</Heading>
            </Box>
            <Box>
              <Button color={"white"} mr="12px" backgroundColor={'#7C2FF1'} size='md' className="btn-hover-pri" onClick={() => {
                // handleURLClick()
                history.push(`/app/email-campaigns`);
                setIsOpen(true)
              }}
                sx={{
                  _focus: {
                    boxShadow: 'none',  // Removes the focus box shadow
                  }
                }}
              >
                <Image src={CreateCampaign} alt='Dan Abramov' />
                <Text as="span" ms="8px">
                  Create New Campaign
                </Text>
              </Button>
              <Link to="/app/leadsHistory">
                <Button color={"#6C757D"} backgroundColor={'white'} size='md' border="1px solid #6C757D" mr="12px" fontSize="16px" className={styles.btnhover} sx={{
                  _focus: {
                    boxShadow: 'none',  // Removes the focus box shadow
                  }
                }}>
                  <Image src={ScrapedHistory} alt='Dan Abramov' />
                  <Text as="span" ms="8px">Scraped Leads History
                  </Text>
                </Button>
              </Link>
            </Box>
          </Flex>

        </Flex>
      </VStack>
      <Box mt="0px">
        <Flex justifyContent="flex-end">
          <InputGroup
            width="400px"
            // border="1px solid #6C757D"
            borderRadius="4px"
            borderColor="#6C757D"
          >
            <InputLeftElement pointerEvents='none'>
              <IoIosSearch color="#6C757D" />
            </InputLeftElement>
            <Input type='text' placeholder='Search by name' color="#6C757D" value={searchValue}
              onChange={handleInputChange} />
          </InputGroup>
        </Flex>
      </Box>
      <Box mt="24px" pt={12} pb={1} backgroundColor="white" boxShadow="sm" borderRadius="8px" minW="100%" w="600px">
        <Text as="div">
          <TableContainer overflow="auto" w="100%">
            <Table variant="simple" >
              <Thead>
                <Tr className={styles.tableHd}>
                  <Th>#</Th>
                  <Th>Name Campaign</Th>
                  <Th>Status</Th>
                  <Th>Details</Th>
                </Tr>
              </Thead>
              <Tbody>
                {inviteUsersData?.data?.data?.length > 0 ? inviteUsersData?.data?.data.map((data: any, index: number) => (
                  <Tr key={data._id} className={styles.tableTd}>
                    <Td> {index + 1 + (currentPage - 1) * itemsPerPages}</Td>
                    <Td>{data?.campaignName}</Td>
                    <Td>
                      {data?.status === "completed" ? (
                        <Text as="span" className={styles.Completed}>Completed</Text>
                      ) : data?.status === "running" ? (
                        <Text as="span" className={styles.Running}>Running</Text>
                      ) : data?.status === "scheduled" ? (
                        <Text as="span" className={styles.Schedule}>Scheduled</Text>
                      ) : data?.status === "initiated" ? (
                        <Text as="span" className={styles.initiated}>Initiated</Text>
                      ) : data?.status === "paused" ? (
                        <Text as="span" className={styles.paused}>Paused</Text>
                      ) : data?.status === "failed" ? (
                        <Text as="span" className={styles.paused}>Failed</Text>
                      ) : null}
                    </Td>
                    <Td>
                      <Button
                        size="sm"
                        fontSize={12}
                        variant="outline"
                        borderColor="#6C757D"
                        onClick={() => {
                          handleURLClick(data);
                        }}
                        mr={2}
                        className={styles.readBtn}
                        sx={{
                          _focus: {
                            boxShadow: 'none',  // Removes the focus box shadow
                          }
                        }}
                      >
                        <ReadIcon />
                      </Button>
                    </Td>
                  </Tr>
                )) : <Tr><Td colSpan={8} textAlign="center">No data found</Td></Tr>}
              </Tbody>
            </Table>
          </TableContainer>
        </Text>
      </Box>
      <Box>
        <PaginatorTwo forcePage={forcePage} onPageChange={handlePageChange} pageRangeDisplayed={5} pageCount={inviteUsersData?.data?.pagination?.totalPages} />
        <HStack w="100%" justifyContent="space-between" p={4}>
          <Flex alignItems="center">
            <Text mr="8px" fontSize="16px" fontWeight="400" color="#212529">Total&nbsp;{inviteUsersData?.data?.pagination?.totalCount}&nbsp;items</Text>

          </Flex>
          <Flex alignItems="center">
            <Select
              value={itemsPerPages}
              onChange={handleItemsPerPageChange}
              width="auto"
              color="#6C757D"
              mr="8px"
            >
              <option value={10}>10 / page</option>
              <option value={20}>20 / page</option>
              <option value={50}>50 / page</option>
            </Select>
          </Flex>
        </HStack>
      </Box>
      <CreateNewCampaignModal fetchData={fetchData} isOpen={isOpen} onClose={onClose} />
      <DetailsOfCampaignModal isOpens={isOpens} onCloses={onCloses} data={modalData} fetchData={fetchData} currentPage={currentPage} itemsPerPages={itemsPerPages} />
      <AddTargetAudience isOpenTargetAudience={isOpenTargetAudience} setIsOpenTargetAudience={setIsOpenTargetAudience} fetchData={fetchData} currentPage={currentPage} itemsPerPages={itemsPerPages} onCloseTargetAudience={onCloseTargetAudience} campaignId={campaignId} campaignNames={campaignName} />
      {/* <CreateNewCampaignModal isOpen={isOpen} onClose={onClose} /> */}
      {/* <DetailsOfCampaignModal isOpens={isOpens} onCloses={onCloses} data={modalData} /> */}
    </Box>
  );
};
