import { Box, Stack, Text, Button, Heading, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import "./EmailTemplateSuperadmin.css";
import { getEmailTemplates, setEmailTemplates } from "../../services/emailTemplatesServices";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface FormData {
  signupEmailText: string;
  forgetPasswordEmailText: string;
  forgetPasswordSuccessEmailText: string;
  inviteUserEmailText: string;
  leadsDataEmailText: string;
  offlineMessagesEmailText: string;
}

export const EmailTemplateSuperadmin = () => {
  const [templates, setTemplates] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isFormTouched, setIsFormTouched] = useState(false);
  const toast = useToast();

  const [formData, setFormData] = useState<FormData>({
    signupEmailText: '',
    forgetPasswordEmailText: '',
    forgetPasswordSuccessEmailText: '',
    inviteUserEmailText: '',
    leadsDataEmailText: '',
    offlineMessagesEmailText: '',
  });

  const validateField = (content: string): string | null => {
    // Strip HTML tags and whitespace to check if there's actual content
    const strippedContent = content.replace(/<[^>]*>/g, '').trim();
    return !strippedContent ? 'This field is required' : null;
  };

  const handleEditorChange = (content: string, editorType: keyof FormData) => {
    setIsFormTouched(true);
    setFormData((prevState) => ({
      ...prevState,
      [editorType]: content,
    }));

    // Validate on change
    const error = validateField(content);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [editorType]: error || '',
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validation
    const newErrors: Record<string, string> = {};
    let isValid = true;

    // Check if any form field is empty
    Object.keys(formData).forEach((key) => {
      const fieldKey = key as keyof FormData;
      const error = validateField(formData[fieldKey]);
      if (error) {
        newErrors[fieldKey] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      toast({
        title: "Please fill in all required fields",
        status: "error",
        isClosable: true,
      });
      return;
    }

    // Gather form data for submission
    const dataToSubmit = { ...formData };

    try {
      setIsSubmitting(true);
      const response = await setEmailTemplates(dataToSubmit);

      if (response?.data?.statusCode === 200) {
        toast({
          title: `${response?.data?.message}`,
          status: "success",
          isClosable: true,
        });
      } else {
        toast({
          title: `${response?.data?.message}`,
          status: "error",
          isClosable: true,
        });
      }

    } catch (error) {
      toast({
        title: `${error}`,
        status: "error",
        isClosable: true,
      });

    } finally {
      setIsSubmitting(false);
    }

  };

  const fetchData = async () => {
    try {
      const templateData = await getEmailTemplates();
      setTemplates(templateData?.data);

      const initialFormData: FormData = {
        signupEmailText: '',
        forgetPasswordEmailText: '',
        forgetPasswordSuccessEmailText: '',
        inviteUserEmailText: '',
        leadsDataEmailText: '',
        offlineMessagesEmailText: '',
      };

      templateData?.data?.getAllTemplates?.forEach((template: any) => {
        initialFormData[template.name as keyof FormData] = template.emailBody || '';
      });

      setFormData(initialFormData);

      // Clear any existing errors when data is fetched
      setErrors({});
      setIsFormTouched(false);

    } catch (error) {
      console.error('Failed to fetch templates:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'code-block', 'align', 'background'
  ];

  const toolbarOptions = [
    [{ header: [1, 2, false] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    ['link', 'image'],
    [{ align: [] }],
    ['code-block'],
    ['clean']
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  return (
    <Box as="form" onSubmit={handleSubmit} w="100%" p="6" shadow="sm" h="100%" position="relative" overflow="auto">
      <Box mb={8}>
        <Heading fontSize="30px" color="#1A202C">Email Templates</Heading>
      </Box>
      <Stack>
        <Box>
          {templates?.getAllTemplates && templates?.getAllTemplates.map((template: any) => (
            <Box mt="26px" key={template.name}>
              <Text as="h3" fontSize="16px" color="#212529" fontWeight="400" mt={'60px'} mb="15px">{template.label}</Text>
              <ReactQuill
                theme="snow"
                value={formData[template.name as keyof FormData]}
                onChange={(content: any) => handleEditorChange(content, template.name)}
                style={{ height: '250px' }}
                placeholder="Type your content here..."
                modules={modules}
                formats={formats}
              />
              {/* Show validation error only if form is touched and there is an error */}
              {isFormTouched && errors[template.name] && (
                <Text color="red.500" fontSize="sm" mt={2}>
                  {errors[template.name]}
                </Text>
              )}
            </Box>
          ))}
          <Box mt={6}>
            <Button
              type="submit"
              colorScheme="blue"
              mt={'60px'}
              width="150px"
              isDisabled={isSubmitting}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};