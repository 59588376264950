import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  InputGroup,
  Input,
  FormControl,
  FormLabel,
  Switch,
  Textarea,
  useToast,
  Spinner
} from "@chakra-ui/react";
import styles from "../InviteUsers.module.scss";
import { useForm } from 'react-hook-form';
import categoriesData from "./categories.json";
import countriesData from "./countries.json";
import { setInviteUser } from "../../../services/inviteUserServices";

export const GmbSearch = ({ isOpen, onClose, inviteUserData }: { isOpen: boolean; onClose: () => void, inviteUserData: any }) => {


  const [selectedCatOptions, setSelectedCatOptions] = useState<any>([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState<any>(null);
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [showBusiness, setShowBusiness] = useState<boolean>(false);
  const [showVerified, setShowVerified] = useState<boolean>(false);
  const [showPhone, setShowPhone] = useState<boolean>(false);
  const [showRating, setShowRating] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();


  const handleCountrySelect = (selectedCountryList: any, selectedCatItem: any) => {
    // Since we want single selection, only keep the first selected item
    setSelectedCountryOption(selectedCatItem);
    if (selectedCountryList.length > 0) {
      clearErrors('country');
    }
  };

  const handleCountryRemove = () => {
    setSelectedCountryOption(null);
    if (!showQuery) {
      setError('country', { type: 'manual', message: 'Country is required' });
    }
  };

  const onSelectCatOptions = (selectedCatList: any) => {
    setSelectedCatOptions(selectedCatList);
    if (selectedCatList.length > 0) {
      clearErrors('categories');
    }
  };
  const onRemoveCatOptions = (selectedCatList: any) => {
    setSelectedCatOptions(selectedCatList);
    if (selectedCatList.length === 0 && !showQuery) {
      setError('categories', { type: 'manual', message: 'Categories are required' });
    }
  };

  const handleQueryToggle = (e: boolean) => {
    setShowQuery(e);
    if (e) {
      clearErrors('categories');
      clearErrors('country');
    }
  };
  const businessToggle = (e: boolean) => {
    setShowBusiness(e);
  };
  const verifiedToggle = (e: boolean) => {
    setShowVerified(e);
  };
  const phoneToggle = (e: boolean) => {
    setShowPhone(e);
  };
  const ratingToggle = (e: boolean) => {
    setShowRating(e);
  };

  const handleRatingValueChange = (e: any) => {
    const value = e.target.value;
    // setInputValue(value);

    // Clear previous error
    clearErrors('ratingValue');

    // Split the input by commas, trim whitespace, and convert to numbers
    const values = value.split(',').map((value: any) => parseFloat(value.trim()));

    // Validate: Check if all numbers are >= 0.1
    const isValid = values.every((value: any) => value >= 0.1 || isNaN(value)); // Allow NaN for empty input

    // Set error if validation fails
    if (!isValid && value) {
      setError('ratingValue', { type: 'manual', message: 'All numbers must be greater than or equal to 0.1' });
    }
  };

  const onSubmit = async (data: any) => {

    setIsSubmitting(true);

    const values = data?.ratingValue?.split(',')?.map((value: any) => parseFloat(value.trim()));

    // Validate: Check if all numbers are >= 0.1
    const isValid = values?.every((value: any) => value >= 0.1 || isNaN(value)); // Allow NaN for empty input

    // Set error if validation fails
    if (!isValid && data?.ratingValue) {
      setError('ratingValue', { type: 'manual', message: 'All numbers must be greater than or equal to 0.1' });
      return;
    }

    if (!showQuery) {
      if (selectedCatOptions.length === 0) {
        setError('categories', { type: 'manual', message: 'Categories are required' });
        return;
      }
      if (!selectedCountryOption && !showQuery) {
        setError('country', { type: 'manual', message: 'Country is required' });
        return;
      }
    }

    // Destructure to exclude unwanted keys based on conditions
    const {
      businessOperator,
      businessValue,
      verifiedOperator,
      verifiedValue,
      phoneOperator,
      phoneValue,
      ratingOperator,
      ratingValue,
      query, // Exclude query if showQuery is false
      ...filteredData
    } = data;

    const formData: any = {
      ...filteredData,
    };

    // Add categories as an array of strings only if it has a value and showQuery is false
    if (!showQuery && selectedCatOptions && selectedCatOptions.length > 0) {
      formData.categories = selectedCatOptions.map((category: any) => category.name);
    }

    // Add country only if it has a value
    if (selectedCountryOption?.code) {
      formData.country = selectedCountryOption.code.toLowerCase();
    }

    // Add query only if showQuery is true
    if (showQuery && query) {
      formData.query = query;
    }

    // Initialize the filter array
    formData.filter = [];

    formData.limit = parseInt(formData?.limit);

    // Add business to the filter array only if showBusiness is true
    if (showBusiness && businessOperator && businessValue) {
      formData.filter.push({
        field: "business status",
        operator: businessOperator,
        value: businessValue,
      });
    }

    // Add verified to the filter array only if showVerified is true
    if (showVerified && verifiedOperator && verifiedValue) {
      formData.filter.push({
        field: "verified",
        operator: verifiedOperator,
        value: verifiedValue,
      });
    }

    // Add phone to the filter array only if showPhone is true
    if (showPhone && phoneOperator && phoneValue) {
      formData.filter.push({
        field: "phone",
        operator: phoneOperator,
        value: phoneValue,
      });
    }

    // Add rating to the filter array only if showRating is true
    if (showRating && ratingOperator && ratingValue) {
      formData.filter.push({
        field: "rating",
        operator: ratingOperator,
        value: ratingValue,
      });
    }

    try {
      const response = await setInviteUser(formData);

      if (response?.data?.statusCode === 200) {
        reset();
        setSelectedCatOptions([]);
        setSelectedCountryOption(null);
        setShowQuery(false);
        setShowBusiness(false);
        setShowVerified(false);
        setShowPhone(false);
        setShowRating(false);
        inviteUserData(1, 10, "");
        onClose();
        toast({
          title: `${response?.data?.message}`,
          status: "success",
          isClosable: true,
        })

      } else {
        toast({
          title: `${response?.data?.message}`,
          status: "error",
          isClosable: true,
        })
      }

    } catch (error) {
      toast({
        title: `${error}`,
        status: "error",
        isClosable: true,
      });

    } finally {
      setIsSubmitting(false);
    }

  };


  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody bg="#F7FAFC">
          <Tabs isFitted variant='enclosed'>
            <TabList mb='0px'>
              {/* <Tab _focus={{ boxShadow: 'none', backgroundColor: "#FFF" }} fontSize="16px">Yellow Pages Search</Tab> */}
              {/* <Tab _focus={{ boxShadow: 'none', backgroundColor: "#FFF" }} fontSize="16px">GMB Search</Tab> */}
            </TabList>
            <TabPanels border="1px solid rgb(222, 226, 230)" bg="#fff">
              {/* <TabPanel>
                  <Box textAlign="center">
                    <Text as="h4" mt="8px" fontSize="18px" fontWeight="700" color="#1A202C">Yellow Pages Search</Text>
                    <Text as="p" mt="8px" fontSize="14px" fontWeight="400" color="#6C757D">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</Text>
                  </Box>
                  <hr style={{ border: "1px soild #E2E8F0", marginTop: "24px", marginBottom: "24px" }} />
                  <Box>
                    <Text as="p" fontSize="16px" fontWeight="400" color="#212529" mb="8px">Yellow Pages Search:</Text>
                    <Flex alignItems="center">
                      <Text as="span" marginRight="8px">
                      </Text>
                      <InputGroup
                        width="400px"
                        borderRadius="4px"
                        borderColor="#6C757D"
                        marginRight="8px"
                      >
                        <Input
                          type="text"
                          placeholder="Search query"
                          color="#6C757D"
                          value={searchValue}
                          onChange={handleInputChange}
                          size="md"
                        />
                      </InputGroup>

                      <Select
                        value={itemsPerPages}
                        onChange={handleItemsPerPageChange}
                        width="auto"
                        color="#6C757D"
                      >
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                      </Select>
                    </Flex>
                  </Box>
                  <Box mt="40px">
                    <Flex justifyContent="flex-end">
                      <Button bg="#6C757D" color="#FFFFFF" mr={3} onClick={onClose} fontSize="16px" fontWeight="400" className={styles.cancaleBtn}>
                        Cancel
                      </Button>
                      <Button bg="#7C2FF1" color="#FFFFFF" mr={3} onClick={onClose} fontSize="16px" fontWeight="400" className="btn-hover-pri">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1778_8843)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.585451 2.58481C0.960523 2.20974 1.46923 1.99902 1.99966 1.99902H13.9997C14.5301 1.99902 15.0388 2.20974 15.4139 2.58481C15.7889 2.95988 15.9997 3.46859 15.9997 3.99902V8.49902C15.9997 8.63163 15.947 8.75881 15.8532 8.85258C15.7594 8.94635 15.6323 8.99902 15.4997 8.99902C15.3671 8.99902 15.2399 8.94635 15.1461 8.85258C15.0523 8.75881 14.9997 8.63163 14.9997 8.49902V5.38202L7.99966 9.58202L6.67366 8.78702L1.03366 12.258C1.09068 12.4706 1.21625 12.6585 1.3909 12.7925C1.56556 12.9264 1.77954 12.9991 1.99966 12.999H7.49966C7.63227 12.999 7.75945 13.0517 7.85322 13.1455C7.94699 13.2392 7.99966 13.3664 7.99966 13.499C7.99966 13.6316 7.94699 13.7588 7.85322 13.8526C7.75945 13.9463 7.63227 13.999 7.49966 13.999H1.99966C1.47096 13.999 0.963761 13.7897 0.588975 13.4168C0.214188 13.0439 0.00230783 12.5377 -0.000335693 12.009V3.99902C-0.000335693 3.46859 0.210378 2.95988 0.585451 2.58481ZM0.999664 11.104L5.70766 8.20702L0.999664 5.38202V11.104ZM0.999664 3.99902V4.21602L7.99966 8.41602L14.9997 4.21602V3.99902C14.9997 3.73381 14.8943 3.47945 14.7068 3.29192C14.5192 3.10438 14.2649 2.99902 13.9997 2.99902H1.99966C1.73445 2.99902 1.48009 3.10438 1.29256 3.29192C1.10502 3.47945 0.999664 3.73381 0.999664 3.99902Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9745 14.9739C15.6309 14.3175 15.9997 13.4273 15.9997 12.499C15.9997 11.5708 15.6309 10.6805 14.9745 10.0241C14.3182 9.36777 13.4279 8.99902 12.4997 8.99902C11.5714 8.99902 10.6812 9.36777 10.0248 10.0241C9.36841 10.6805 8.99966 11.5708 8.99966 12.499C8.99966 13.4273 9.36841 14.3175 10.0248 14.9739C10.6812 15.6303 11.5714 15.999 12.4997 15.999C13.4279 15.999 14.3182 15.6303 14.9745 14.9739ZM13.8229 10.7543C13.8879 10.7639 13.9503 10.7863 14.0067 10.82C14.063 10.8538 14.1122 10.8983 14.1513 10.951C14.1904 11.0038 14.2188 11.0637 14.2348 11.1274C14.2507 11.1911 14.254 11.2573 14.2444 11.3223C14.2348 11.3873 14.2124 11.4497 14.1787 11.506L12.8437 13.732C12.785 13.8298 12.7048 13.9129 12.6092 13.9751C12.5136 14.0372 12.405 14.0767 12.2918 14.0906C12.1786 14.1045 12.0638 14.0924 11.9559 14.0552C11.8481 14.018 11.7502 13.9567 11.6697 13.876L10.8957 13.103C10.8018 13.0091 10.749 12.8818 10.749 12.749C10.749 12.6162 10.8018 12.4889 10.8957 12.395C10.9896 12.3011 11.1169 12.2484 11.2497 12.2484C11.3824 12.2484 11.5098 12.3011 11.6037 12.395L12.1507 12.942L13.3207 10.992C13.3544 10.9357 13.3989 10.8865 13.4517 10.8474C13.5044 10.8083 13.5643 10.7799 13.628 10.7639C13.6917 10.7479 13.758 10.7447 13.8229 10.7543Z" fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1778_8843">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <Text as="span" ms="8px">
                          Fetch Record
                        </Text>

                      </Button>
                    </Flex>
                  </Box>
                </TabPanel> */}
              <TabPanel>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box textAlign="center">
                    <Text as="h4" mt="8px" fontSize="18px" fontWeight="700" color="#1A202C">GMB Search</Text>
                    <Text as="p" mt="8px" fontSize="14px" fontWeight="400" color="#6C757D">Scrape the data from the GMB listings by using any of the keywords below:</Text>

                  </Box>
                  <hr style={{ border: "1px soild #E2E8F0", marginTop: "24px", marginBottom: "24px" }} />
                  <Box>
                    <Text as="p" fontSize="16px" fontWeight="400" color="#212529" mb="8px">Search GMB:</Text>
                    <FormControl mb={"-20px"}>
                      <Flex w="100%" alignItems="center" justifyContent={"flex-end"}>

                        <FormLabel fontWeight="400" fontSize="14px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                          Plain queries
                        </FormLabel>
                        <Switch
                          defaultChecked={false}
                          onChange={(event) => { handleQueryToggle(event.target.checked) }}
                          colorScheme="blue"
                          size="sm"
                          outline={"none"}
                          mr="2"
                        />
                      </Flex>
                    </FormControl>
                    <Box alignItems="center">
                      {!showQuery && <Box mb={"15px"}>
                        <Text as="span" marginRight="8px">
                          Categories
                        </Text>
                        <Multiselect
                          options={categoriesData}
                          // name="particulars"
                          onSelect={onSelectCatOptions}
                          onRemove={onRemoveCatOptions}
                          displayValue="name"
                          closeIcon="cancel"
                          placeholder="Select Options"
                          selectedValues={selectedCatOptions}
                          className="multiSelectContainer"
                        />
                        {errors.categories && <p style={{ color: 'red', fontSize: '12px' }}>{errors.categories.message}</p>}
                      </Box>}
                      {showQuery && <Box mb={"15px"}>
                        <Text as="span" marginRight="8px">
                          Queries (category + city + country)
                        </Text>
                        <InputGroup
                          borderRadius="4px"
                          borderColor="#6C757D"
                          marginRight="8px"
                        >
                          <Textarea
                            id="query"
                            placeholder="Real estate agency, Rome, Italy
Doctor, London, UK
Restaurant, New York, US
(Press Enter to add a new line)"
                            color="#6C757D"
                            // onChange={''}
                            rows={5}
                            {...register('query', { required: 'Search query is required' })}
                          />
                        </InputGroup>
                        {errors.query && <p style={{ color: 'red', fontSize: '12px' }}>{errors.query.message}</p>}
                      </Box>}
                      <Flex gap={2}>
                        <Box mb={"15px"} w={"50%"}>
                          <Text as="span" marginRight="8px">
                            Country
                          </Text>
                          <Multiselect
                            options={countriesData}
                            // singleSelect
                            selectedValues={selectedCountryOption ? [selectedCountryOption] : []}
                            onSelect={handleCountrySelect}
                            onRemove={handleCountryRemove}
                            displayValue={"name"}
                            showCheckbox={false} // Hide checkboxes for single select
                            placeholder="Select an option"
                          // search={true} // Enable the search box
                          />
                          {errors.country && <p style={{ color: 'red', fontSize: '12px' }}>{errors.country.message}</p>}
                        </Box>
                        <Box mb={"15px"} w={"50%"}>
                          <Text as="span" marginRight="8px">
                            Limits
                          </Text>
                          <Select
                            // onChange={''}
                            width="auto"
                            color="#6C757D"
                            {...register('limit', { required: 'Limit is required' })}
                          >
                            <option value={''}>Select Limit</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={150}>150</option>
                            <option value={200}>200</option>
                            <option value={250}>250</option>
                            <option value={300}>300</option>
                            <option value={350}>350</option>
                            <option value={400}>400</option>
                            <option value={450}>450</option>
                            <option value={500}>500</option>
                          </Select>
                          {errors.limit && <p style={{ color: 'red', fontSize: '12px' }}>{errors.limit.message}</p>}
                        </Box>
                      </Flex>
                      <Box mb={"15px"}>
                        <Text fontWeight={600} marginRight="8px" mt={3} mb="10px">
                          Advanced parameters
                        </Text>
                        <Box mb={"15px"}>
                          <FormControl mb={"0px"}>
                            <Flex w="100%" alignItems="center" justifyContent={"space-between"}>
                              <FormLabel fontWeight="500" fontSize="16px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                                Business Status
                              </FormLabel>
                              <Switch
                                defaultChecked={false}
                                onChange={(event) => { businessToggle(event.target.checked) }}
                                colorScheme="blue"
                                size="md"
                                outline={"none"}
                                mr="2"
                              />
                            </Flex>
                          </FormControl>
                          {showBusiness && <Box p={"10px"} mb={"15px"}>
                            <Flex w="100%" alignItems="center" gap={2} justifyContent={"space-between"}>
                              <Box w="50%">
                                <Text as="span" fontSize={"14px"} marginRight="8px">
                                  Operator
                                </Text>
                                <Select
                                  // onChange={''}
                                  width="auto"
                                  color="#6C757D"
                                  {...register('businessOperator', { required: 'Operator is required' })}
                                >
                                  <option value={'Equals'}>Equals</option>
                                </Select>
                                {errors.businessOperator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.businessOperator.message}</p>}
                              </Box>
                              <Box w="50%">
                                <Text as="span" fontSize={"14px"} marginRight="8px">
                                  Values (one of)
                                </Text>
                                <Select
                                  // onChange={''}
                                  width="auto"
                                  color="#6C757D"
                                  {...register('businessValue', { required: 'Value is required' })}
                                >
                                  <option value={''}>Select Value</option>
                                  <option value={'operational'}>Operational</option>
                                  <option value={'closed_temporarily'}>Closed Temporarily</option>
                                  <option value={'closed_permanently'}>Closed Permanently</option>
                                </Select>
                                {errors.businessValue && <p style={{ color: 'red', fontSize: '12px' }}>{errors.businessValue.message}</p>}
                              </Box>
                            </Flex>
                          </Box>}
                        </Box>
                        <Box mb={"15px"}>
                          <FormControl mb={"0px"}>
                            <Flex w="100%" alignItems="center" justifyContent={"space-between"}>

                              <FormLabel fontWeight="500" fontSize="16px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                                Verified
                              </FormLabel>
                              <Switch
                                defaultChecked={false}
                                onChange={(event) => { verifiedToggle(event.target.checked) }}
                                colorScheme="blue"
                                size="md"
                                outline={"none"}
                                mr="2"
                              />
                            </Flex>
                          </FormControl>
                          {showVerified && <Box p={"10px"} mb={"15px"}>
                            <Flex w="100%" alignItems="center" gap={2} justifyContent={"space-between"}>
                              <Box w="50%">
                                <Text as="span" fontSize="14px" marginRight="8px">
                                  Operator
                                </Text>
                                <Select
                                  // onChange={''}
                                  width="auto"
                                  color="#6C757D"
                                  {...register('verifiedOperator', { required: 'Operator is required' })}
                                >
                                  <option value={'Equals'}>Equals</option>
                                </Select>
                                {errors.verifiedOperator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.verifiedOperator.message}</p>}
                              </Box>
                              <Box w="50%">
                                <Text as="span" fontSize="14px" marginRight="8px">
                                  Values (one of)
                                </Text>
                                <Select
                                  // onChange={''}
                                  width="auto"
                                  color="#6C757D"
                                  {...register('verifiedValue', { required: 'Value is required' })}
                                >
                                  <option value={''}>Select Value</option>
                                  <option value={'true'}>True</option>
                                  <option value={'false'}>False</option>
                                </Select>
                                {errors.verifiedValue && <p style={{ color: 'red', fontSize: '12px' }}>{errors.verifiedValue.message}</p>}
                              </Box>
                            </Flex>
                          </Box>}
                        </Box>
                        <Box mb={"15px"}>
                          <FormControl mb={"0px"}>
                            <Flex w="100%" alignItems="center" justifyContent={"space-between"}>

                              <FormLabel fontWeight="500" fontSize="16px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                                Phone
                              </FormLabel>
                              <Switch
                                defaultChecked={false}
                                onChange={(event) => { phoneToggle(event.target.checked) }}
                                colorScheme="blue"
                                size="md"
                                outline={"none"}
                                mr="2"
                              />
                            </Flex>
                          </FormControl>
                          {showPhone && <Box p={"10px"} mb={"15px"}>
                            <Flex w="100%" alignItems="center" gap={2} justifyContent={"space-between"}>
                              <Box w="50%">
                                <Text as="span" fontSize="14px" marginRight="8px">
                                  Operator
                                </Text>
                                <Select
                                  // onChange={''}
                                  width="auto"
                                  color="#6C757D"
                                  {...register('phoneOperator', { required: 'Operator is required' })}
                                >
                                  <option value={'Equals'}>Equals</option>
                                </Select>
                                {errors.phoneOperator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.phoneOperator.message}</p>}
                              </Box>
                              <Box w="50%">
                                <Text as="span" fontSize="14px" marginRight="8px">
                                  Values (one of)
                                </Text>
                                <Select
                                  // onChange={''}
                                  width="auto"
                                  color="#6C757D"
                                  {...register('phoneValue', { required: 'Value is required' })}
                                >
                                  <option value={''}>Select Value</option>
                                  <option value={'id_not_blank'}>Is not blank</option>
                                  <option value={'is_blank'}>Is blank</option>
                                </Select>
                                {errors.phoneValue && <p style={{ color: 'red', fontSize: '12px' }}>{errors.phoneValue.message}</p>}
                              </Box>
                            </Flex>
                          </Box>}
                        </Box>
                        <Box mb={"15px"}>
                          <FormControl mb={"0px"}>
                            <Flex w="100%" alignItems="center" justifyContent={"space-between"}>

                              <FormLabel fontWeight="500" fontSize="16px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                                Rating
                              </FormLabel>
                              <Switch
                                defaultChecked={false}
                                onChange={(event) => { ratingToggle(event.target.checked) }}
                                colorScheme="blue"
                                size="md"
                                outline={"none"}
                                mr="2"
                              />
                            </Flex>
                          </FormControl>
                          {showRating && <Box p={"10px"} mb={"15px"}>
                            <Flex w="100%" alignItems="center" gap={2} justifyContent={"space-between"}>
                              <Box w="50%">
                                <Text as="span" fontSize="14px" marginRight="8px">
                                  Operator
                                </Text>
                                <Select
                                  // onChange={''}
                                  width="auto"
                                  color="#6C757D"
                                  {...register('ratingOperator', { required: 'Operator is required' })}
                                >
                                  <option value={''}>Select Operator</option>
                                  <option value={'Contains_One_Of'}>Contains One Of</option>
                                  <option value={'Contains_None_Of'}>Contains None Of</option>
                                  <option value={'Starts_With'}>starts with</option>
                                  <option value={'Equals'}>Equals</option>
                                </Select>
                                {errors.ratingOperator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.ratingOperator.message}</p>}
                              </Box>
                              <Box w="50%">
                                <Text as="span" fontSize="14px" marginRight="8px">
                                  Values
                                </Text>
                                <InputGroup
                                  borderRadius="4px"
                                  borderColor="#6C757D"
                                  marginRight="8px"
                                >
                                  <Input
                                    type="text"
                                    placeholder="4.1, 3.6, 5.5 etc."
                                    color="#6C757D"
                                    size="md"
                                    {...register('ratingValue', { required: 'Value is required' })}
                                    onChange={handleRatingValueChange}
                                  />
                                </InputGroup>
                                {errors.ratingValue && <p style={{ color: 'red', fontSize: '12px' }}>{errors.ratingValue.message}</p>}
                              </Box>
                            </Flex>
                          </Box>}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt="40px">
                    <Flex justifyContent="flex-end">
                      <Button bg="#6C757D" color="#FFFFFF" mr={3} onClick={onClose} fontSize="16px" fontWeight="400" className={styles.cancaleBtn}>
                        Cancel
                      </Button>
                      <Button type="submit" bg="#7C2FF1" color="#FFFFFF" mr={3} fontSize="16px" fontWeight="400" display={"flex"} alignItems={"center"} gap={'5px'} className="btn-hover-pri" disabled={isSubmitting}>
                        {isSubmitting ? <Spinner color='gray.700' />
                        :
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1778_8843)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.585451 2.58481C0.960523 2.20974 1.46923 1.99902 1.99966 1.99902H13.9997C14.5301 1.99902 15.0388 2.20974 15.4139 2.58481C15.7889 2.95988 15.9997 3.46859 15.9997 3.99902V8.49902C15.9997 8.63163 15.947 8.75881 15.8532 8.85258C15.7594 8.94635 15.6323 8.99902 15.4997 8.99902C15.3671 8.99902 15.2399 8.94635 15.1461 8.85258C15.0523 8.75881 14.9997 8.63163 14.9997 8.49902V5.38202L7.99966 9.58202L6.67366 8.78702L1.03366 12.258C1.09068 12.4706 1.21625 12.6585 1.3909 12.7925C1.56556 12.9264 1.77954 12.9991 1.99966 12.999H7.49966C7.63227 12.999 7.75945 13.0517 7.85322 13.1455C7.94699 13.2392 7.99966 13.3664 7.99966 13.499C7.99966 13.6316 7.94699 13.7588 7.85322 13.8526C7.75945 13.9463 7.63227 13.999 7.49966 13.999H1.99966C1.47096 13.999 0.963761 13.7897 0.588975 13.4168C0.214188 13.0439 0.00230783 12.5377 -0.000335693 12.009V3.99902C-0.000335693 3.46859 0.210378 2.95988 0.585451 2.58481ZM0.999664 11.104L5.70766 8.20702L0.999664 5.38202V11.104ZM0.999664 3.99902V4.21602L7.99966 8.41602L14.9997 4.21602V3.99902C14.9997 3.73381 14.8943 3.47945 14.7068 3.29192C14.5192 3.10438 14.2649 2.99902 13.9997 2.99902H1.99966C1.73445 2.99902 1.48009 3.10438 1.29256 3.29192C1.10502 3.47945 0.999664 3.73381 0.999664 3.99902Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9745 14.9739C15.6309 14.3175 15.9997 13.4273 15.9997 12.499C15.9997 11.5708 15.6309 10.6805 14.9745 10.0241C14.3182 9.36777 13.4279 8.99902 12.4997 8.99902C11.5714 8.99902 10.6812 9.36777 10.0248 10.0241C9.36841 10.6805 8.99966 11.5708 8.99966 12.499C8.99966 13.4273 9.36841 14.3175 10.0248 14.9739C10.6812 15.6303 11.5714 15.999 12.4997 15.999C13.4279 15.999 14.3182 15.6303 14.9745 14.9739ZM13.8229 10.7543C13.8879 10.7639 13.9503 10.7863 14.0067 10.82C14.063 10.8538 14.1122 10.8983 14.1513 10.951C14.1904 11.0038 14.2188 11.0637 14.2348 11.1274C14.2507 11.1911 14.254 11.2573 14.2444 11.3223C14.2348 11.3873 14.2124 11.4497 14.1787 11.506L12.8437 13.732C12.785 13.8298 12.7048 13.9129 12.6092 13.9751C12.5136 14.0372 12.405 14.0767 12.2918 14.0906C12.1786 14.1045 12.0638 14.0924 11.9559 14.0552C11.8481 14.018 11.7502 13.9567 11.6697 13.876L10.8957 13.103C10.8018 13.0091 10.749 12.8818 10.749 12.749C10.749 12.6162 10.8018 12.4889 10.8957 12.395C10.9896 12.3011 11.1169 12.2484 11.2497 12.2484C11.3824 12.2484 11.5098 12.3011 11.6037 12.395L12.1507 12.942L13.3207 10.992C13.3544 10.9357 13.3989 10.8865 13.4517 10.8474C13.5044 10.8083 13.5643 10.7799 13.628 10.7639C13.6917 10.7479 13.758 10.7447 13.8229 10.7543Z" fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1778_8843">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        }
                        <Text as="span" ms="8px">
                          Fetch Record
                        </Text>

                      </Button>
                    </Flex>
                  </Box>
                </form>
              </TabPanel>

            </TabPanels>
          </Tabs>
          {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}


        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
