import React, { useCallback, useState } from "react";
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Divider,
  FormControl,
  Select,
  Image,
  Input,
  HStack,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton,
  Menu,
  MenuList,
  MenuItem,
  Checkbox,
  Wrap,
  MenuButton,
  Flex,
  InputGroup,
  Textarea,
  Switch,
  useToast,
} from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import { useForm } from "react-hook-form";
import { setInviteUser } from "../../../services/inviteUserServices";
import categoriesData from "../../Invite Users/Modal/categories.json";
import countriesData from "../../Invite Users/Modal/countries.json";
import styles from "../EmailCampaignsAdmin.module.scss";
import { dataScrappingForCampaign } from "../../../services/emailCampaigns";
import { SuccessfullyModal } from "./SuccessfullyModal";
import { useHistory } from "react-router-dom";
import { CancelThisCampaign } from "./CancelThisCampaign";

export const GMBSearchForCreateCampaign = ({ dataForClose, campaignId, campaignName }: { dataForClose: () => void, campaignId: any, campaignName: any }) => {
  const toast = useToast();
  const [selectedCatOptions, setSelectedCatOptions] = useState<any>([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState<any>(null);
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [showBusiness, setShowBusiness] = useState<boolean>(false);
  const [showVerified, setShowVerified] = useState<boolean>(false);
  const [showPhone, setShowPhone] = useState<boolean>(false);
  const [showRating, setShowRating] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCancelThisCampaign, setIsCancelThisCampaign] = useState<boolean>(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const handleCountrySelect = (selectedCountryList: any, selectedCatItem: any) => {
    // Since we want single selection, only keep the first selected item
    setSelectedCountryOption(selectedCatItem);
    if (selectedCountryList.length > 0) {
      clearErrors('country');
    }
  };

  const handleCountryRemove = () => {
    setSelectedCountryOption(null);
    if (!showQuery) {
      setError('country', { type: 'manual', message: 'Country is required' });
    }
  };

  const onSelectCatOptions = (selectedCatList: any) => {
    setSelectedCatOptions(selectedCatList);
    if (selectedCatList.length > 0) {
      clearErrors('categories');
    }
  };


  const onRemoveCatOptions = (selectedCatList: any) => {
    setSelectedCatOptions(selectedCatList);
    if (selectedCatList.length === 0 && !showQuery) {
      setError('categories', { type: 'manual', message: 'Categories are required' });
    }
  };

  const handleQueryToggle = (e: boolean) => {
    setShowQuery(e);
    if (e) {
      clearErrors('categories');
      clearErrors('country');
    }
  };
  const businessToggle = (e: boolean) => {
    setShowBusiness(e);
  };
  const verifiedToggle = (e: boolean) => {
    setShowVerified(e);
  };
  const phoneToggle = (e: boolean) => {
    setShowPhone(e);
  };
  const ratingToggle = (e: boolean) => {
    setShowRating(e);
  };

  const handleRatingValueChange = (e: any) => {
    const value = e.target.value;
    // setInputValue(value);

    // Clear previous error
    clearErrors('ratingValue');

    // Split the input by commas, trim whitespace, and convert to numbers
    const values = value.split(',').map((value: any) => parseFloat(value.trim()));

    // Validate: Check if all numbers are >= 0.1
    const isValid = values.every((value: any) => value >= 0.1 || isNaN(value)); // Allow NaN for empty input

    // Set error if validation fails
    if (!isValid && value) {
      setError('ratingValue', { type: 'manual', message: 'All numbers must be greater than or equal to 0.1' });
    }
  };

  const onSubmit = async (data: any) => {



    const values = data?.ratingValue?.split(',')?.map((value: any) => parseFloat(value.trim()));

    // Validate: Check if all numbers are >= 0.1
    const isValid = values?.every((value: any) => value >= 0.1 || isNaN(value)); // Allow NaN for empty input

    // Set error if validation fails
    if (!isValid && data?.ratingValue) {
      setError('ratingValue', { type: 'manual', message: 'All numbers must be greater than or equal to 0.1' });
      return;
    }

    if (!showQuery) {
      if (selectedCatOptions.length === 0) {
        setError('categories', { type: 'manual', message: 'Categories are required' });
        return;
      }
      if (!selectedCountryOption && !showQuery) {
        setError('country', { type: 'manual', message: 'Country is required' });
        return;
      }
    }

    // Destructure to exclude unwanted keys based on conditions
    const {
      businessOperator,
      businessValue,
      verifiedOperator,
      verifiedValue,
      phoneOperator,
      phoneValue,
      ratingOperator,
      ratingValue,
      query, // Exclude query if showQuery is false
      ...filteredData
    } = data;

    const formData: any = {
      ...filteredData,
    };

    // Add categories as an array of strings only if it has a value and showQuery is false
    if (!showQuery && selectedCatOptions && selectedCatOptions.length > 0) {
      formData.categories = selectedCatOptions.map((category: any) => category.name);
    }

    // Add country only if it has a value
    if (selectedCountryOption?.code) {
      formData.country = selectedCountryOption.code.toLowerCase();
    }

    // Add query only if showQuery is true
    if (showQuery && query) {
      formData.query = query;
    }

    // Initialize the filter array
    formData.filter = [];

    formData.limit = parseInt(formData?.limit);

    // Add business to the filter array only if showBusiness is true
    if (showBusiness && businessOperator && businessValue) {
      formData.filter.push({
        field: "business status",
        operator: businessOperator,
        value: businessValue,
      });

    }

    // Add verified to the filter array only if showVerified is true
    if (showVerified && verifiedOperator && verifiedValue) {
      formData.filter.push({
        field: "verified",
        operator: verifiedOperator,
        value: verifiedValue,
      });
    }

    // Add phone to the filter array only if showPhone is true
    if (showPhone && phoneOperator && phoneValue) {
      formData.filter.push({
        field: "phone",
        operator: phoneOperator,
        value: phoneValue,
      });
    }

    // Add rating to the filter array only if showRating is true
    if (showRating && ratingOperator && ratingValue) {
      formData.filter.push({
        field: "rating",
        operator: ratingOperator,
        value: ratingValue,
      });
    }
    const newData = {
      ...formData,
      campaignId: campaignId
    };
    try {
      setIsSubmitting(true);
      const response = await dataScrappingForCampaign(newData);
      if (response?.data?.data.length > 0) {
        reset();
        setSelectedCatOptions([]);
        setSelectedCountryOption(null);

        toast({
          title: `${response?.data?.message}`,
          status: "success",
          isClosable: true,
        })
        dataForClose()
        history.push(`/app/email-campaigns?campaignId=${campaignId}&campaignName=${campaignName}&targetAudience=${true}`);
        setIsOpen(true);
      } else {
        setIsSubmitting(false);
        toast({
          title: `${response?.data?.message}`,
          status: "error",
          isClosable: true,
        })
      }

    } catch (error: any) {
      // console.log(error);
      // dataForClose();
      toast({
        title: `${error?.response?.data?.message}`,
        status: "error",
        isClosable: true,
      });

    } finally {
      // setIsSubmitting(false);
    }

  };

  const onCloseCanceled = () => {
    setIsOpen(false);
  }

  const onCancelThisCampaign = () => {
    setIsCancelThisCampaign(false);
  }

  const onCancelCampaign = () => {
    setIsCancelThisCampaign(true);
  }
  const handleChildData = () => {
    dataForClose();
    history.push(`/app/email-campaigns`);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box textAlign="center">
          <Text as="h4" mt="8px" fontSize="18px" fontWeight="700" color="#1A202C">GMB Search</Text>
          <Text as="p" mt="8px" fontSize="14px" fontWeight="400" color="#6C757D">Scrape the data from the GMB listings by using any of the keywords below:</Text>

        </Box>
        <hr style={{ border: "1px soild #E2E8F0", marginTop: "24px", marginBottom: "24px" }} />
        <Box>
          <Text as="p" fontSize="16px" fontWeight="400" color="#212529" mb="8px">Search GMB:</Text>
          <FormControl mb={"-20px"}>
            <Flex w="100%" alignItems="center" justifyContent={"flex-end"}>

              <FormLabel fontWeight="400" fontSize="14px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                Plain queries
              </FormLabel>
              <Switch
                defaultChecked={false}
                onChange={(event) => { handleQueryToggle(event.target.checked) }}
                colorScheme="blue"
                size="sm"
                outline={"none"}
                mr="2"
              />
            </Flex>
          </FormControl>
          <Box alignItems="center">
            {!showQuery && <Box mb={"15px"}>
              <Text as="span" marginRight="8px">
                Categories
              </Text>
              <Multiselect
                options={categoriesData}
                // name="particulars"
                onSelect={onSelectCatOptions}
                onRemove={onRemoveCatOptions}
                displayValue="name"
                closeIcon="cancel"
                placeholder="Select Options"
                selectedValues={selectedCatOptions}
                className="multiSelectContainer"
              />
              {errors.categories && <p style={{ color: 'red', fontSize: '12px' }}>{errors.categories.message}</p>}
            </Box>}
            {showQuery && <Box mb={"15px"}>
              <Text as="span" marginRight="8px">
                Queries (category + city + country)
              </Text>
              <InputGroup
                borderRadius="4px"
                borderColor="#6C757D"
                marginRight="8px"
              >
                <Textarea
                  id="query"
                  placeholder="Real estate agency, Rome, Italy
Doctor, London, UK
Restaurant, New York, US
(Press Enter to add a new line)"
                  color="#6C757D"
                  // onChange={''}
                  rows={5}
                  {...register('query', { required: 'Search query is required' })}
                />
              </InputGroup>
              {errors.query && <p style={{ color: 'red', fontSize: '12px' }}>{errors.query.message}</p>}
            </Box>}
            <Flex gap={2}>
              <Box mb={"15px"} w={"50%"}>
                <Text as="span" marginRight="8px">
                  Country
                </Text>
                <Multiselect
                  options={countriesData}
                  // singleSelect
                  selectedValues={selectedCountryOption ? [selectedCountryOption] : []}
                  onSelect={handleCountrySelect}
                  onRemove={handleCountryRemove}
                  displayValue={"name"}
                  showCheckbox={false} // Hide checkboxes for single select
                  placeholder="Select an option"
                // search={true} // Enable the search box
                />
                {errors.country && <p style={{ color: 'red', fontSize: '12px' }}>{errors.country.message}</p>}
              </Box>
              <Box mb={"15px"} w={"50%"}>
                <Text as="span" marginRight="8px">
                  Limits
                </Text>
                <Select
                  // onChange={''}
                  width="auto"
                  color="#6C757D"
                  {...register('limit', { required: 'Limit is required' })}
                >
                  <option value={''}>Select Limit</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={150}>150</option>
                  <option value={200}>200</option>
                  <option value={250}>250</option>
                  <option value={300}>300</option>
                  <option value={350}>350</option>
                  <option value={400}>400</option>
                  <option value={450}>450</option>
                  <option value={500}>500</option>
                </Select>
                {errors.limit && <p style={{ color: 'red', fontSize: '12px' }}>{errors.limit.message}</p>}
              </Box>
            </Flex>
            <Box mb={"15px"}>
              <Text fontWeight={600} marginRight="8px" mt={3} mb="10px">
                Advanced parameters
              </Text>
              <Box mb={"15px"}>
                <FormControl mb={"0px"}>
                  <Flex w="100%" alignItems="center" justifyContent={"space-between"}>
                    <FormLabel fontWeight="500" fontSize="16px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                      Business Status
                    </FormLabel>
                    <Switch
                      defaultChecked={false}
                      onChange={(event) => { businessToggle(event.target.checked) }}
                      colorScheme="blue"
                      size="md"
                      outline={"none"}
                      mr="2"
                    />
                  </Flex>
                </FormControl>
                {showBusiness && <Box p={"10px"} mb={"15px"}>
                  <Flex w="100%" alignItems="center" gap={2} justifyContent={"space-between"}>
                    <Box w="50%">
                      <Text as="span" fontSize={"14px"} marginRight="8px">
                        Operator
                      </Text>
                      <Select
                        // onChange={''}
                        width="auto"
                        color="#6C757D"
                        {...register('businessOperator', { required: 'Operator is required' })}
                      >
                        <option value={'Equals'}>Equals</option>
                      </Select>
                      {errors.businessOperator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.businessOperator.message}</p>}
                    </Box>
                    <Box w="50%">
                      <Text as="span" fontSize={"14px"} marginRight="8px">
                        Values (one of)
                      </Text>
                      <Select
                        // onChange={''}
                        width="auto"
                        color="#6C757D"
                        {...register('businessValue', { required: 'Value is required' })}
                      >
                        <option value={''}>Select Value</option>
                        <option value={'operational'}>Operational</option>
                        <option value={'closed_temporarily'}>Closed Temporarily</option>
                        <option value={'closed_permanently'}>Closed Permanently</option>
                      </Select>
                      {errors.businessValue && <p style={{ color: 'red', fontSize: '12px' }}>{errors.businessValue.message}</p>}
                    </Box>
                  </Flex>
                </Box>}
              </Box>
              <Box mb={"15px"}>
                <FormControl mb={"0px"}>
                  <Flex w="100%" alignItems="center" justifyContent={"space-between"}>

                    <FormLabel fontWeight="500" fontSize="16px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                      Verified
                    </FormLabel>
                    <Switch
                      defaultChecked={false}
                      onChange={(event) => { verifiedToggle(event.target.checked) }}
                      colorScheme="blue"
                      size="md"
                      outline={"none"}
                      mr="2"
                    />
                  </Flex>
                </FormControl>
                {showVerified && <Box p={"10px"} mb={"15px"}>
                  <Flex w="100%" alignItems="center" gap={2} justifyContent={"space-between"}>
                    <Box w="50%">
                      <Text as="span" fontSize="14px" marginRight="8px">
                        Operator
                      </Text>
                      <Select
                        // onChange={''}
                        width="auto"
                        color="#6C757D"
                        {...register('verifiedOperator', { required: 'Operator is required' })}
                      >
                        <option value={'Equals'}>Equals</option>
                      </Select>
                      {errors.verifiedOperator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.verifiedOperator.message}</p>}
                    </Box>
                    <Box w="50%">
                      <Text as="span" fontSize="14px" marginRight="8px">
                        Values (one of)
                      </Text>
                      <Select
                        // onChange={''}
                        width="auto"
                        color="#6C757D"
                        {...register('verifiedValue', { required: 'Value is required' })}
                      >
                        <option value={''}>Select Value</option>
                        <option value={'true'}>True</option>
                        <option value={'false'}>False</option>
                      </Select>
                      {errors.verifiedValue && <p style={{ color: 'red', fontSize: '12px' }}>{errors.verifiedValue.message}</p>}
                    </Box>
                  </Flex>
                </Box>}
              </Box>
              <Box mb={"15px"}>
                <FormControl mb={"0px"}>
                  <Flex w="100%" alignItems="center" justifyContent={"space-between"}>

                    <FormLabel fontWeight="500" fontSize="16px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                      Phone
                    </FormLabel>
                    <Switch
                      defaultChecked={false}
                      onChange={(event) => { phoneToggle(event.target.checked) }}
                      colorScheme="blue"
                      size="md"
                      outline={"none"}
                      mr="2"
                    />
                  </Flex>
                </FormControl>
                {showPhone && <Box p={"10px"} mb={"15px"}>
                  <Flex w="100%" alignItems="center" gap={2} justifyContent={"space-between"}>
                    <Box w="50%">
                      <Text as="span" fontSize="14px" marginRight="8px">
                        Operator
                      </Text>
                      <Select
                        // onChange={''}
                        width="auto"
                        color="#6C757D"
                        {...register('phoneOperator', { required: 'Operator is required' })}
                      >
                        <option value={'Equals'}>Equals</option>
                      </Select>
                      {errors.phoneOperator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.phoneOperator.message}</p>}
                    </Box>
                    <Box w="50%">
                      <Text as="span" fontSize="14px" marginRight="8px">
                        Values (one of)
                      </Text>
                      <Select
                        // onChange={''}
                        width="auto"
                        color="#6C757D"
                        {...register('phoneValue', { required: 'Value is required' })}
                      >
                        <option value={''}>Select Value</option>
                        <option value={'id_not_blank'}>Is not blank</option>
                        <option value={'is_blank'}>Is blank</option>
                      </Select>
                      {errors.phoneValue && <p style={{ color: 'red', fontSize: '12px' }}>{errors.phoneValue.message}</p>}
                    </Box>
                  </Flex>
                </Box>}
              </Box>
              <Box mb={"15px"}>
                <FormControl mb={"0px"}>
                  <Flex w="100%" alignItems="center" justifyContent={"space-between"}>

                    <FormLabel fontWeight="500" fontSize="16px" color="#212529" mb="0" mr="2" htmlFor="showAsPopup">
                      Rating
                    </FormLabel>
                    <Switch
                      defaultChecked={false}
                      onChange={(event) => { ratingToggle(event.target.checked) }}
                      colorScheme="blue"
                      size="md"
                      outline={"none"}
                      mr="2"
                    />
                  </Flex>
                </FormControl>
                {showRating && <Box p={"10px"} mb={"15px"}>
                  <Flex w="100%" alignItems="center" gap={2} justifyContent={"space-between"}>
                    <Box w="50%">
                      <Text as="span" fontSize="14px" marginRight="8px">
                        Operator
                      </Text>
                      <Select
                        // onChange={''}
                        width="auto"
                        color="#6C757D"
                        {...register('ratingOperator', { required: 'Operator is required' })}
                      >
                        <option value={''}>Select Operator</option>
                        <option value={'Contains_One_Of'}>Contains One Of</option>
                        <option value={'Contains_None_Of'}>Contains None Of</option>
                        <option value={'Starts_With'}>starts with</option>
                        <option value={'Equals'}>Equals</option>
                      </Select>
                      {errors.ratingOperator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.ratingOperator.message}</p>}
                    </Box>
                    <Box w="50%">
                      <Text as="span" fontSize="14px" marginRight="8px">
                        Values
                      </Text>
                      <InputGroup
                        borderRadius="4px"
                        borderColor="#6C757D"
                        marginRight="8px"
                      >
                        <Input
                          type="text"
                          placeholder="4.1, 3.6, 5.5 etc."
                          color="#6C757D"
                          size="md"
                          {...register('ratingValue', { required: 'Value is required' })}
                          onChange={handleRatingValueChange}
                        />
                      </InputGroup>
                      {errors.ratingValue && <p style={{ color: 'red', fontSize: '12px' }}>{errors.ratingValue.message}</p>}
                    </Box>
                  </Flex>
                </Box>}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt="40px">
          <Flex justifyContent="flex-end">
            <Button bg="#6C757D" color="#FFFFFF" mr={3}
              onClick={onCancelCampaign}
              fontSize="16px" fontWeight="400" className={styles.cancaleBtn} >
              Cancel
            </Button>
            <Button type="submit" bg="#7C2FF1" color="#FFFFFF" mr={3} fontSize="16px" fontWeight="400" className="btn-hover-pri" disabled={isSubmitting}>
              <Text as="span" ms="8px">
                Fetch Record
              </Text>
            </Button>
          </Flex>
        </Box>
      </form>
      <CancelThisCampaign isCancelThisCampaign={isCancelThisCampaign} onCancelThisCampaign={onCancelThisCampaign} sendData={handleChildData} />
      <SuccessfullyModal isOpens={isOpen} onCloseCancel={onCloseCanceled} />
    </>
  )
}
