import * as React from "react";
import {
  Box,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  Th,
  Select,
  TableContainer,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Input,
  useToast
} from "@chakra-ui/react";
import styles from "./LeadsHistory.module.scss";
import { IoIosSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import { getAllLeads } from "../../services/emailCampaigns";
import { PaginatorTwo } from "../../widgets/Paginator/PaginatorTwo";
import { format } from "date-fns";



export const LeadsHistory = () => {
  const toast = useToast();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [itemsPerPages, setItemsPerPage] = React.useState<number>(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setSearchValue] = React.useState('');
  const [forcePage, setForcePage] = React.useState<number>(0);
  const [inviteUsersData, setInviteUsersData] = React.useState<any>();
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState(searchValue);
  const [isSearchActive, setIsSearchActive] = React.useState<boolean>(false);


  const handlePageChange = (page: number) => {
    let pages: number = page + 1;
    setCurrentPage(pages);
    setForcePage(page);
  };
  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
    setForcePage(0);
  };

  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value); // Update state with the input value
  };

  const fetchData = async (page: number, pageSize: number, searchKeyword: string) => {

    try {
      const response: any = await getAllLeads(pageSize, page, searchKeyword);
      setInviteUsersData(response);
    } catch (error: any) {
      // toast({
      //   title: `${error?.response?.data?.message}`,
      //   status: "error",
      //   isClosable: true,
      // })
    }
  };


  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);

      if (searchValue !== "") {
        setIsSearchActive(true);
        setCurrentPage(1);
        setForcePage(0);
      } else {
        setIsSearchActive(false);
      }
    }, 1500);

    return () => clearTimeout(handler);
  }, [searchValue]);

  React.useEffect(() => {
    fetchData(currentPage, itemsPerPages, debouncedSearchValue);
  }, [debouncedSearchValue, itemsPerPages, currentPage]);


  return (
    <Box
      w="100%"
      p="6"
      shadow="sm"
      h="100%"
      position="relative"
      overflow="auto"
    >
      <VStack w="100%" spacing="10">
        <Flex
          shrink={0}
          w="100%"
          direction="column"
          justifyContent="space-between"
        >
          <Flex width="100%" mb="8" justifyContent="space-between">
            <Box>
              <Heading as="h2" fontSize="16px" color="#7C2FF1"><Link to={`/app/email-campaigns`}><Text as="span" borderBottom="1px solid #7C2FF1">Ultimamail Campaign</Text></Link> / <Text as="span" color="#6C757D">Scraped Leads History</Text></Heading>
            </Box>
            <Box>
            </Box>
          </Flex>

        </Flex>
      </VStack>
      <Box mt="0px">
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text as="p" fontSize="30px" color="#1A202C" fontWeight="600">Scraped Leads History</Text>
          </Box>
          <Box ml="auto">
            <InputGroup
              width="400px"
              borderRadius="4px"
              borderColor="#6C757D"
            >
              <InputLeftElement pointerEvents="none">
                <IoIosSearch color="#6C757D" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search by URL or Email ID"
                color="#6C757D"
                value={searchValue}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Box>
        </Flex>
      </Box>
      <Box mt="24px" pt={12} pb={1} backgroundColor="white" boxShadow="sm" borderRadius="8px" minW="100%" w="600px">
        <Text as="div">
          <TableContainer overflow="auto" w="100%">
            <Table variant="simple" >
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Lead Creation<br /><Text fontSize="12px">(Date & Time)</Text></Th>
                  <Th w="300px" maxW="300px">Website URL</Th>
                  <Th>Email ID</Th>
                  <Th>Campaign Name</Th>
                  <Th>Email Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {inviteUsersData?.data?.data?.length > 0 ? inviteUsersData?.data?.data.map((data: any, index: number) => (
                  <Tr key={data._id}>
                    <Td> {index + 1 + (currentPage - 1) * itemsPerPages}</Td>
                    <Td fontSize="14px" fontWeight="400" color="#6C757D" >{format(new Date(data?.updatedAt), 'dd-MM-yyyy, HH:mm')}</Td>
                    <Td style={{ width: '300px', maxWidth: '300px', wordWrap: "break-word", wordBreak: "break-all", whiteSpace: 'normal' }}>
                      {data?.websiteUrl}
                    </Td>
                    <Td>
                      {data?.email}
                    </Td>
                    <Td>
                      {data?.campaignName}
                    </Td>
                    <Td>
                      {data?.emailStatus === "sent" ? (
                        <Text as="span" className={styles.Completed}>Sent</Text>
                      ) : data?.emailStatus === "failed" ? (
                        <Text as="span" className={styles.paused}>Failed</Text>
                      ) : data?.emailStatus === "initiated" ? (
                        <Text as="span" className={styles.Schedule}>Initiated</Text>
                      ) : null}
                    </Td>

                  </Tr>
                )) : <Tr><Td colSpan={8} textAlign="center">No data found</Td></Tr>}
              </Tbody>
            </Table>
          </TableContainer>
        </Text>
      </Box>
      <Box>
        <HStack w="100%" justifyContent="space-between" p={4}>
          <Flex alignItems="center">
            <Text mr="8px" fontSize="16px" fontWeight="400" color="#212529">Total&nbsp;{inviteUsersData?.data?.pagination?.totalCount}&nbsp;items</Text>
            <PaginatorTwo forcePage={forcePage} onPageChange={handlePageChange} pageRangeDisplayed={5} pageCount={inviteUsersData?.data?.pagination?.totalPages} />
          </Flex>
          <Flex alignItems="center">
            <Select
              value={itemsPerPages}
              onChange={handleItemsPerPageChange}
              width="auto"
              color="#6C757D"
              mr="8px"
            >
              <option value={10}>10 / page</option>
              <option value={20}>20 / page</option>
              <option value={50}>50 / page</option>
            </Select>
          </Flex>
        </HStack>
      </Box>

    </Box>
  );
};
