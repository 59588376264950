import * as React from "react";
import {
  Input,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  FormErrorMessage,
  Spinner,
  Text,

} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { createEmailSenderSignature } from "../../../services/ultimamailsettings";
import CryptoJS from 'crypto-js'; // Import crypto-js for encryption

type FormValues = {
  email: string;
};


export const AddNewModal = ({ isOpen, onClose, accountAuthToken, fetchData }: { isOpen: boolean; onClose: () => void; fetchData: () => void, accountAuthToken: any }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>();

  const decryptPasskey = (encryptedPasskey: string) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPasskey, 'your-encryption-key');
    const decryptedPasskey = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedPasskey;
  };

  const onSubmit = async (formData: any) => {
    const secureEmailServerData = {
      ...formData,
      // authKey: decryptPasskey(accountAuthToken?.emailAccountAuthKey),
      authKey: accountAuthToken?.emailAccountAuthKey,
    };


    try {
      setIsLoading(true);
      const response = await createEmailSenderSignature(secureEmailServerData);
      if (response.status === 200) {
        fetchData();
        toast({
          title: `${response?.data?.message}`,
          status: "success",
          isClosable: true,
        })
        reset();
        onClose();
      }
    } catch (error: any) {
      toast({
        title: `${error?.response?.data?.message}`,
        status: "error",
        isClosable: true,
      })
    } finally {
      setIsLoading(false);
    }
  }
  const onCancel = () => {
    reset();
    onClose();
  }

  return (

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <ModalHeader>Add Email Id</ModalHeader> */}
          <ModalBody mt="24px">
            <FormControl mb="26px" isInvalid={!!errors.email}>
              <FormLabel>Admin Email ID</FormLabel>
              <Input
                placeholder="Enter Admin Email ID"
                {...register("email", {
                  required: "Auth Key is required",
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter a valid email address",
                  },
                })}
              />
              {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} type="submit">

              {isLoading ?
                <Spinner color='gray.700' />
                :
                <>
                  {/* <Image src={saveIcon} alt='Save & Continue' /> */}
                  <Text as="span" fontSize="16px" ms="8px" fontWeight="400">Save</Text>
                </>
              }
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
