import React, { ChangeEvent } from 'react'
import { leads, leadsPagination } from '../../types/knowledgebase.type'
import { Box, Button, Flex, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Select, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { Paginator } from '../../widgets/Paginator/Paginator'
import styles from "./OfflineMessagesList.module.scss"
import { leadsDataSortData } from '../../services/knowledgebaseService'
import { PaginatorTwo } from '../../widgets/Paginator/PaginatorTwo'
import { verify } from 'crypto'
type OfflineMessagesListProps = {
  isChatListLoading: boolean;
  chatSessionsPage: leadsPagination;
  selectedChat: leads;
  onPageChange: (page: number) => void;
  onSelectChat: (chatSession: leads) => void;
  knowledgeBaseId: string;
  pageNumber: number;
}

export const LeadsList = ({
  isChatListLoading,
  chatSessionsPage,
  selectedChat,
  onPageChange,
  knowledgeBaseId,
  pageNumber,
  onSelectChat }: OfflineMessagesListProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [crawlDatLoading, setCrawlDatLoading] = React.useState<any>('');
  const [searchValue, setSearchValue] = React.useState('');
  const [leadsData, setLeadsData] = React.useState(chatSessionsPage);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [forcePage, setForcePage] = React.useState<number>(0);
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState(searchValue);
  const [isSearchActive, setIsSearchActive] = React.useState<boolean>(false);



  const handleURLClick = React.useCallback(async (data: any) => {
    onOpen();
    setCrawlDatLoading(data);
  }, [onOpen]);
  const [itemsPerPages, setItemsPerPage] = React.useState<number>(10);
  const [itemsPerPage, setItemsPerPages] = React.useState<number>(-1);

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
    setForcePage(0);
  };

  const handleItemsPerPageChanges = (event: ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPages(parseInt(event.target.value, 10));
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);
  };



  const handlePageChange = React.useCallback((page) => {
    setCurrentPage(page + 1);
    setForcePage(page)
  }, [setCurrentPage]);

  const fetchData = async (knowledgeBaseId: string, itemsPerPages: number, currentPage: number, itemsPerPage: number, searchValue: string) => {

    try {
      const userData = await leadsDataSortData(knowledgeBaseId, itemsPerPages, currentPage, itemsPerPage, searchValue);
      setLeadsData(userData.data);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {

    }
  };


  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);

      if (searchValue !== "") {
        setIsSearchActive(true);
        setCurrentPage(1);
        setForcePage(0);
      } else {
        setIsSearchActive(false);
      }
    }, 1500);

    return () => clearTimeout(handler);
  }, [searchValue,]);


  React.useEffect(() => {
    fetchData(knowledgeBaseId, itemsPerPages, currentPage, itemsPerPage, debouncedSearchValue);
  }, [knowledgeBaseId, itemsPerPages, currentPage, itemsPerPage, debouncedSearchValue]);
  return (
    <Box w="100%"
      pos="relative"
    >
      {
        isChatListLoading && <Flex
          pos="absolute"
          align="center"
          justify="center"
          top={0}
          bottom={0}
          right={0}
          left={0}
          bg="whiteAlpha.700"
        >
          <Spinner />
        </Flex>
      }

      {/* <Box
                overflowY="auto"
                overflowX="hidden"
                h="calc(100% - 47px)"
                borderRight="1px"
                borderColor="gray.200"
            >
                <Flex direction="column">
                    {
                        chatSessionsPage.results
                            .map((chatSession: Leads) => (
                                <OfflineChatListItem
                                    key={chatSession._id}
                                    chatSessionData={chatSession}
                                    isSelected={selectedChat._id === chatSession._id}
                                    onSelectChat={() => onSelectChat(chatSession)}
                                />
                            ))
                    }
                </Flex>
            </Box> */}
      <Box display="flex" justifyContent="flex-end" mb="20px">
        <Text as="span" marginRight="8px">
          <Input type='text' value={searchValue} onChange={handleInputChange} placeholder="Search" />
        </Text>
        <Text as="span" marginRight="8px">
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChanges}
            width="auto"
            color="#6C757D"
            mr="8px"
          >
            <option value={-1}>New to Old</option>
            <option value={1}>Old to New</option>
          </Select>
        </Text>
      </Box>


      <Text as="div">
        <TableContainer overflow="hidden">
          <Table variant="simple" >
            <Thead>
              <Tr
                className={styles.tableHd}
              >
                <Th>#</Th>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Address</Th>
                <Th>Phone</Th>
                <Th>Date/Time</Th>
              </Tr>
            </Thead>
            <Tbody>
              {leadsData?.results?.map((chatSession: leads, index: number) => (
                <Tr key={index}
                  className={styles.tableTd}
                >
                  <Td>{index + 1 + (currentPage - 1) * itemsPerPages}</Td>
                  <Td>
                    {/* {chatSession?.firstName === undefined ? "-" : chatSession?.firstName} */}
                    {chatSession?.firstName ?? chatSession?.name ?? '-' }
                  </Td>
                  <Td>
                    {/* {chatSession.email === "" ? "-" : chatSession.email} */}
                    {chatSession?.email === undefined ? "-" : chatSession?.email}
                  </Td>
                  <Td>
                    {/* {chatSession.address.slice(0, 30)}{chatSession.address.length > 30 && '...'} */}
                    {chatSession?.address === undefined ? "-" : chatSession?.address}
                  </Td>
                  <Td>
                    {/* {chatSession.phone} */}
                    {chatSession?.phone === undefined ? "-" : chatSession?.phone}
                  </Td>
                  <Td>{new Date(chatSession.createdAt).toLocaleString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                  })}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>

          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple" >
              <Tbody>
                <Tr
                  className={styles.tableTr}
                >
                  <Th>Name</Th>
                  <Td>
                    {/* {crawlDatLoading.name} */}
                  </Td>
                </Tr>
                <Tr
                  className={styles.tableTr}
                >
                  <Th>Email</Th>
                  {/* <Td>{crawlDatLoading.email}</Td> */}
                </Tr>
                <Tr
                  className={styles.tableTr}
                >
                  <Th>Message</Th>
                  {/* <Td>{crawlDatLoading.message}</Td> */}
                </Tr>
              </Tbody>
            </Table>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HStack w="full" justifyContent="space-between" p={4}>
        <Flex alignItems="center">
          <Text mr="8px" fontSize="16px" fontWeight="400" color="#212529">Total {leadsData?.totalCount} items</Text>
          {/* <Paginator onPageChange={handlePageChange} pageRangeDisplayed={5} pageCount={leadsData.pages} /> */}
          <PaginatorTwo onPageChange={handlePageChange} pageRangeDisplayed={5} pageCount={leadsData?.pages} forcePage={forcePage} />
        </Flex>
        <Flex alignItems="center">
          <Select
            value={itemsPerPages}
            onChange={handleItemsPerPageChange}
            width="auto"
            color="#6C757D"
            mr="8px"
          >
            <option value={10}>10 / page</option>
            <option value={20}>20 / page</option>
            <option value={50}>50 / page</option>
          </Select>
        </Flex>
      </HStack>

    </Box >
  )
}