import * as React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  Th,
  TableContainer,
  Button
} from "@chakra-ui/react";
import styles from "./UltimamailSettings.module.scss";
import { Link } from "react-router-dom";
import { ReadIcon } from "../../components/Icons/ReadIcon";
import { useHistory } from "react-router-dom";
import { emailServerConfig } from "../../services/ultimamailsettings";
import CryptoJS from 'crypto-js'; // Import crypto-js for encryption
import { ENCRYPTION_DECRYPTION_KEY } from '../../config';


export const UltimamailSettingsHistory = () => {
  const history = useHistory();
  const [inviteUsersData, setInviteUsersData] = React.useState<any>([]);


  const decryptPasskey = (encryptedPasskey: string) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPasskey, ENCRYPTION_DECRYPTION_KEY);
    const decryptedPasskey = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedPasskey;
  };

  const fetchData = async () => {
    try {
      const response: any = await emailServerConfig();
      const decryptedUsers = response?.data?.data?.map((user: any) => ({
        ...user,
        emailAccountAuthKey: decryptPasskey(user?.emailAccountAuthKey), // Decrypt the passkey here
        emailServerApiKey: decryptPasskey(user?.emailServerApiKey)
      }));
      setInviteUsersData(decryptedUsers);

    } catch (error: any) {

    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
      <Box
        w="100%"
        p="6"
        shadow="sm"
        h="100%"
        position="relative"
        overflow="auto"
      >
        <VStack w="100%" spacing="10">
          <Flex
            shrink={0}
            w="100%"
            direction="column"
            justifyContent="space-between"
          >
            <Flex width="100%" mb="8" justifyContent="space-between">
              <Box>
                <Heading as="h2" fontSize="16px" color="#7C2FF1">
                  <Link to={`/app/ultimamailsettings`}><Text as="span" borderBottom="1px solid #7C2FF1">Ultimamail Settings</Text>
                  </Link> / <Text as="span" color="#6C757D">Settings History</Text></Heading>
              </Box>
              <Box>
              </Box>
            </Flex>

          </Flex>
        </VStack>
        <Box mt="0px">
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text as="p" fontSize="30px" color="#1A202C" fontWeight="600">Ultimamail Settings History</Text>
            </Box>
          </Flex>
        </Box>
        <Box mt="24px" pt={12} pb={1} backgroundColor="white" boxShadow="sm" borderRadius="8px" minW="100%" w="600px">
          <Text as="div">
            <TableContainer overflow="auto" w="100%">
              <Table variant="simple" >
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Email</Th>
                    <Th >Mail Server</Th>
                    <Th>Auth Key</Th>
                    <Th>Secret Key</Th>
                    <Th>Details</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {inviteUsersData?.length > 0 ? inviteUsersData?.map((data: any, index: number) => (
                    <Tr key={data._id}>
                      <Td> {index + 1}</Td>
                      <Td>{data?.adminEmail}</Td>
                      <Td>{data?.emailServiceProviderName}</Td>
                      <Td>{data?.emailAccountAuthKey}</Td>
                      <Td>{data?.emailServerApiKey}</Td>
                      <Td>

                        <Button
                          size="sm"
                          fontSize={12}
                          variant="outline"
                          borderColor="#6C757D"
                          onClick={() => {
                            history.push(`/app/settingDetails/${data?._id}`);
                          }}
                          mr={2}
                          className={styles.readBtn}
                          sx={{
                            _focus: {
                              boxShadow: 'none',  // Removes the focus box shadow
                            }
                          }}
                        >
                          <ReadIcon />
                        </Button>

                      </Td>
                    </Tr>
                  )) : <Tr><Td colSpan={8} textAlign="center">No data found</Td></Tr>}
                </Tbody>
              </Table>
            </TableContainer>
          </Text>
        </Box>
      </Box>
    </>
  );
};
