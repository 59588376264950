import { LauncherIconsSVGs } from "./LauncherIconSVGs";

export function getDomainFromUrl(url: string) {
    if (!url) return '';
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    const domain = matches && matches[1]; // domain will be null if no match is found
    return (domain || '').replace('www.', '');
}


export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 15) >> (c === 'x' ? 0 : 4);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export function getBrowserName(userAgent: string) {

  if(!userAgent) return 'Other';

  // Detect ARC Welder
  if (userAgent.indexOf('ARC') > -1) {
    return 'ARC Welder';
  }

  // Detect Chrome
  if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1 && userAgent.indexOf("OPR") === -1) {
    return "Google Chrome";
  }

  // Detect Firefox
  if (userAgent.indexOf("Firefox") > -1) {
    return "Mozilla Firefox";
  }

  // Detect Safari
  if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
    return "Safari";
  }

  // Detect Internet Explorer or Legacy Edge
  if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
    return "Microsoft Internet Explorer";
  }

  // Detect Edge (Chromium)
  if (userAgent.indexOf("Edg") > -1) {
    return "Microsoft Edge";
  }

  // Detect Opera
  if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    return "Opera";
  }

  // Other
  return 'Other';
}

export const chatWidgetDefaultValues = {
	backgroundColor: "#000",
	heading: 'I am your AI assistant',
	description: `Ask me anything. I'll try to answer based on the data from this website.`,
  chatInputPlaceholderText: 'Type your message',
  assistantTabHeader: 'AI assistant',
  offlineMsgTabHeader: 'Offline message',
  readMoreText: 'Read more:',
	fontColor: "#FFF",
	borderRadius: "12px",
	placement: "right",
	offlineMessage: false,
	showReadMore: true,
  showAsPopup: false,
  popupDelay: 3000,
  collectEmail: false,
  adminEmail: '',
  collectEmailText: 'What is your email address?',
  contentEmail: '',
    contentPhone: '',
    contentWhatsapp: '',
    contentVideo: '',
    contentAppointment: '',
  questionExamples: [
    {heading: 'Welcome Video Link', question: 'https_vide0_welcome', label: 'Video'},
    {heading: 'Welcome Book Appointment Link', question: 'https_calendaly_welcome', label: 'Book an appointment'},
    {heading: "Call Link (if chatbot don't answer", question: 'https_Call_welcome', label: 'Call'},
    {heading: "WhatsApp Link (if chatbot don't answer", question: 'https_WhatsApp_welcome', label: 'WhatsApp'},
    {heading: "Email Link (if chatbot don't answer", question: 'https_Email_welcome', label: 'Email'},
  ],
  welcomeMessages: [
    'Hello! How can I assist you today?'
  ],
  welcomeMessage: 'Hello! How can I assist you today?',
  customCSS: '',
  prompt: 'You are a very enthusiastic chatbot who loves to help people! Your name is {{chatbotName}} and you are designed to respond only based on the given context, outputted in Markdown format.',
  defaultAnswer: "I don't know how to answer that",
  launcherIcon: { 
    id: 'icon1', 
    svgElement: LauncherIconsSVGs.get('icon1') 
  },
  offlineMsgHeading: "Offlne message",
  offlineMsgDescription: "Please fill out the form below and we will get back to you as soon as possible.",
  nameFieldLabel: "Name",
  nameFieldPlaceholder: "Enter your name",
  emailFieldLabel: "Email",
  emailFieldPlaceholder: "Enter your email",
  msgFieldLabel: "Message",
  msgFieldPlaceholder: "Enter your message",
  requiredFieldMsg: "This field is required",
  invalidEmailMsg: "Please enter a valid email",
  formSubmitBtnLabel: "Submit",
  formSubmitBtnSubmittingText: "Submitting...",
  formSubmitSuccessMsg: "Your message sent successfully!",
  formSubmitErrorMsg: "Oops! Something went wrong",
  formSendAgainBtnLabel: "Send again",
  formTryAgainBtnLabel: "Try again",
  model: 'gpt-3.5-turbo',
  leadCollectionData:[
    {phoneNumber: false , address: false}
  ]
};

export const chatWidgetDefaultValuesForVakmangids = {
	backgroundColor: "#000",
	heading: 'Ik ben uw AI-assistent',
	description: `Vraag me alles. Ik zal proberen te antwoorden op basis van de gegevens van deze website.`,
  chatInputPlaceholderText: 'Typ uw bericht',
  assistantTabHeader: 'AI-assistent',
  offlineMsgTabHeader: 'Offline bericht',
  readMoreText: 'Lees meer:',
	fontColor: "#FFF",
	borderRadius: "12px",
	placement: "rechts",
	offlineMessage: false,
	showReadMore: true,
  showAsPopup: false,
  popupDelay: 3000,
  collectEmail: false,
  adminEmail: '',
  collectEmailText: 'Wat is uw e-mailadres?',
  contentEmail: '',
    contentPhone: '',
    contentWhatsapp: '',
    contentVideo: '',
    contentAppointment: '',
  questionExamples: [
    {heading: 'Welkomstvideo Link', question: 'https_vide0_welcome', label: 'Video'},
    {heading: 'Welkom Afspraak Boeken Link', question: 'https_calendaly_welcome', label: 'Boek een afspraak'},
    {heading: "Bel Link (als chatbot niet antwoordt)", question: 'https_Call_welcome', label: 'Bellen'},
    {heading: "WhatsApp Link (als chatbot niet antwoordt)", question: 'https_WhatsApp_welcome', label: 'WhatsApp'},
    {heading: "E-mail Link (als chatbot niet antwoordt)", question: 'https_Email_welcome', label: 'E-mail'},
  ],
  welcomeMessages: [
    'Hallo! Hoe kan ik u vandaag helpen?'
  ],
  welcomeMessage: 'Hallo! Hoe kan ik u vandaag helpen?',
  customCSS: '',
  prompt: 'U bent een zeer enthousiaste chatbot die graag mensen helpt! Uw naam is {{chatbotName}} en u bent ontworpen om alleen te reageren op basis van de gegeven context, uitvoer in Markdown-indeling.',
  defaultAnswer: "Ik weet niet hoe ik daarop moet antwoorden",
  launcherIcon: { 
    id: 'icon1', 
    svgElement: LauncherIconsSVGs.get('icon1') 
  },
  offlineMsgHeading: "Offline bericht",
  offlineMsgDescription: "Vul het onderstaande formulier in en wij nemen zo spoedig mogelijk contact met u op.",
  nameFieldLabel: "Naam",
  nameFieldPlaceholder: "Voer uw naam in",
  emailFieldLabel: "E-mail",
  emailFieldPlaceholder: "Voer uw e-mail in",
  msgFieldLabel: "Bericht",
  msgFieldPlaceholder: "Voer uw bericht in",
  requiredFieldMsg: "Dit veld is verplicht",
  invalidEmailMsg: "Voer een geldig e-mailadres in",
  formSubmitBtnLabel: "Verzenden",
  formSubmitBtnSubmittingText: "Verzenden...",
  formSubmitSuccessMsg: "Uw bericht is succesvol verzonden!",
  formSubmitErrorMsg: "Oeps! Er is iets misgegaan",
  formSendAgainBtnLabel: "Opnieuw verzenden",
  formTryAgainBtnLabel: "Opnieuw proberen",
  model: 'gpt-3.5-turbo',
  leadCollectionData:[
    {phoneNumber: false , address: false}
  ]
};


export function formatNumber(num:number, precision = 0) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}
