import React, { useState, useEffect, useRef } from "react";
import Multiselect from "multiselect-react-dropdown";
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Divider,
  FormControl,
  Image,
  Input,
  useToast,
  ModalCloseButton,
} from "@chakra-ui/react";
import ExcelLogo from "./images/excelLogo.svg"
import { useForm } from "react-hook-form";
import { CancelThisCampaign } from "./CancelThisCampaign";
import { CampaignTrigger } from "./CampaignTrigger";
import { GMBSearchForCreateCampaign } from "./GMBSearchForCreateCampaign";
import CsvFile from "./csv_data_sample.csv";
import { csvParseAndSaveData, duplicateLead, getAllLeadsWithCount } from "../../../services/emailCampaigns";
import { useHistory, useLocation, Link } from 'react-router-dom';
type Steps =
  | "previous campaigns"
  | "Upload Leads"
  | "Scrape New Leads";


export const AddTargetAudience = ({ isOpenTargetAudience, onCloseTargetAudience, setIsOpenTargetAudience, campaignId, campaignNames, fetchData, currentPage, itemsPerPages }: any) => {
  const [isSubmittings, setIsSubmittings] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [isCancelThisCampaign, setIsCancelThisCampaign] = useState(false);
  const [selectedCatOptions, setSelectedCatOptions] = useState<any>([]);
  const [currentStep, setCurrentStep] = React.useState<Steps>(
    "previous campaigns"
  );
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [isOpenCampaignTrigger, setIsOpenCampaignTrigger] = useState<boolean>(false);
  // const [targetAudience, setTargetAudience] = useState<any>('false');

  const history = useHistory();
  const mountedRef = useRef(false);

  const onCloseCampaignTrigger = () => {
    setIsOpenCampaignTrigger(false)
  }

  const handleTabChanges = (index: number) => {
    const steps = [
      'previous campaigns',
      'Upload Leads',
      'Scrape New Leads',
    ];
  };

  // Explicitly type the ref as a mutable reference to an HTMLInputElement or null
  const fileInputRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);

  const handleExcelButton = () => {
    fileInputRef.current?.click(); // Safely calls click if fileInputRef.current is not null
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("campaignId", campaignId);
        formData.append("campaignName", campaignNames);
        const response: any = await csvParseAndSaveData(formData)
        toast({
          title: `${response?.data?.message}`,
          status: "success",
          isClosable: true,
        });
        onCloseTargetAudience();
        history.push(`/app/email-campaigns?campaignId=${campaignId}&campaignName=${campaignNames}&targetAudience=${true}`);
        setIsOpenCampaignTrigger(true);
      }
    } catch (error: any) {
      toast({
        title: `${error?.response?.data?.message}`,
        status: "error",
        isClosable: true,
      })
    }

  };






  const toast = useToast();

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();


  const onCancelThisCampaign = () => {
    setIsCancelThisCampaign(false);
  }

  const handleChildData = () => {
    onCloseTargetAudience();
  };
  const [previousCampaigns, setPreviousCampaigns] = useState([]);

  const onSelectCatOptions = (selectedCatList: any) => {
    setSelectedCatOptions(selectedCatList);
    if (selectedCatList.length > 0) {
      clearErrors('categories');
    }
  };

  const onRemoveCatOptions = (selectedCatList: any) => {
    setSelectedCatOptions(selectedCatList);
    if (selectedCatList.length === 0 && !showQuery) {
      setError('categories', { type: 'manual', message: 'previous campaign are required' });
      setCount(0);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await getAllLeadsWithCount();
        const campaigns = response?.data?.data;
        setPreviousCampaigns(campaigns); // Updated here

      } catch (error: any) {
        toast({
          title: `${error?.response?.data?.message}`,
          status: "error",
          isClosable: true,
        });
      }
    };
    if (isOpenTargetAudience) {
      fetchData();
    }
  }, [isOpenTargetAudience]);

  const dataForCloseModal = () => {
    onCloseTargetAudience();
  }

  const onCloseCanceled = () => {
    setIsOpen(false);
  }


  const previouscampaignsBtn = async () => {
    if (!showQuery) {
      if (selectedCatOptions.length === 0) {
        setError('categories', { type: 'manual', message: 'previous campaign are required' });
        return;
      }
    }
    const formData: any = {};

    if (!showQuery && selectedCatOptions && selectedCatOptions.length > 0) {
      formData.categories = selectedCatOptions.map((category: any) => category);
    }



    const formattedData = {
      currentCampaignId: campaignId,
      previousCampaign: formData.categories.slice(0).map((campaign: any) => ({
        campaignId: campaign.campaignId,
        campaignName: campaign.campaignName
      }))
    };
    try {
      setIsSubmitting(true);
      const response = await duplicateLead(formattedData);
      if (response?.data?.statusCode === 200) {
        setSelectedCatOptions([]);
        toast({
          title: `${response?.data?.message}`,
          status: "success",
          isClosable: true,
        })
        onCloseTargetAudience()
        history.push(`/app/email-campaigns?campaignId=${campaignId}&campaignName=${campaignNames}&targetAudience=${true}`);
        setIsOpenCampaignTrigger(true);
      } else {
        toast({
          title: `${response?.data?.message}`,
          status: "error",
          isClosable: true,
        })
      }

    } catch (error: any) {
      toast({
        title: `${error?.response?.data?.message}`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (selectedCatOptions.length === 0) {
      return undefined
    } else {
      const totalCountCamp = selectedCatOptions.reduce((total: number, campaign: any) => total + campaign.count, 0);
      setCount(totalCountCamp); // This will only run when selectedCatOptions changes
    }
  }, [selectedCatOptions]);

  const location = useLocation();

  useEffect(() => {
    // Set mounted to true when component mounts
    mountedRef.current = true;

    const init = async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const targetAudience = searchParams.get('targetAudience');
        const review = searchParams.get('review');
        if (targetAudience === 'true') {
          // Only proceed if still mounted
          if (mountedRef.current) {
            await onCloseTargetAudience();
            // Check again before setting state
            if (mountedRef.current && review === null) {
              setIsOpenCampaignTrigger(true);
            }
          }
        }
      } catch (error) {
        // console.error('Error:', error);
      }
    };

    init();

    // Cleanup function that runs when component unmounts
    return () => {
      mountedRef.current = false;
    };
  }, [onCloseTargetAudience]);


  return (
    <>
      <Modal isOpen={isOpenTargetAudience} closeOnOverlayClick={false} size="xl" onClose={onCloseTargetAudience} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent bg="#F7FAFC">
          <ModalCloseButton _focus={{ outline: "none" }} as={Link} to="/app/email-campaigns" />
          <ModalHeader textAlign="center" fontSize="18px" fontWeight="700" color="#1A202C" pb="8px">Target Audience</ModalHeader>
          <Text as="span" textAlign="center" fontSize="14px" fontWeight="400" color="#6C757D" pb="24px">Please add more target audience for this campaign</Text>
          <ModalBody>
            <Tabs variant="enclosed" w="100%" colorScheme='gray' size="md"
              onChange={handleTabChanges}
              index={
                ['previous campaigns', 'Upload Leads', 'Scrape New Leads'].indexOf(currentStep)
              }
            >
              <TabList>
                <Tab
                  id="tab-0"
                  isDisabled={isSubmittings}
                  _focus={{ outline: "none" }}
                  _disabled={{ cursor: "not-allowed", opacity: "0.4" }}
                  style={{
                    backgroundColor: currentStep === "previous campaigns" ? '#fff' : '',
                    color: currentStep === "previous campaigns" ? '#495057' : '#7C2FF1',
                    fontSize: '16px',
                  }}
                  onClick={() => setCurrentStep("previous campaigns")}
                >
                  Duplicate Leads from<br />previous campaigns
                </Tab>
                <Tab
                  id="tab-1"
                  isDisabled={isSubmittings}
                  _focus={{ outline: "none" }}
                  _disabled={{ cursor: "not-allowed", opacity: "0.4" }}
                  style={{
                    backgroundColor: currentStep === "Upload Leads" ? '#fff' : '',
                    color: currentStep === "Upload Leads" ? '#495057' : '#7C2FF1',
                    fontSize: '16px',
                  }}
                  onClick={() => setCurrentStep("Upload Leads")}
                >
                  Upload Leads
                </Tab>
                <Tab
                  id="tab-8"
                  isDisabled={isSubmittings}
                  _focus={{ outline: "none" }}
                  _disabled={{ cursor: "not-allowed", opacity: "0.4" }}
                  style={{
                    backgroundColor: currentStep === "Scrape New Leads" ? '#fff' : '',
                    color: currentStep === "Scrape New Leads" ? '#495057' : '#7C2FF1',
                    fontSize: '16px',
                  }}
                  onClick={() => setCurrentStep("Scrape New Leads")}
                >
                  Scrape New Leads
                </Tab>
              </TabList>
              <TabPanels border="1px solid #DEE2E6" bg="#fff">
                <TabPanel pt="8">
                  <Box textAlign="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center">
                    <Text as="p" fontSize="18px" fontWeight="700" color="#1A202C" pb="8px">Select your previous campaigns</Text>
                    <Text as="p" fontSize="14px" fontWeight="400" color="#6C757D" pb="24px">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took </Text>
                    <Divider orientation='horizontal' color="#E2E8F0" mb="24px" />
                  </Box>
                  <Box>
                    <form>
                      <FormControl isRequired mb="32px">
                        {!showQuery && (
                          <Box mb="15px">
                            <Multiselect
                              options={previousCampaigns} // Use updated state
                              onSelect={onSelectCatOptions}
                              onRemove={onRemoveCatOptions}
                              displayValue="campaignName"
                              closeIcon="cancel"
                              placeholder="Select Options"
                              selectedValues={selectedCatOptions}
                              className="multiSelectContainer"
                            />
                            {errors.categories && (
                              <p style={{ color: 'red', fontSize: '12px' }}>{errors.categories.message}</p>
                            )}
                          </Box>)}
                      </FormControl>
                    </form>
                    <Text as="p" fontSize="18px" fontWeight="700" color="#1A202C" pb="8px" >Total Number of leads {count}</Text>
                    <Box display="flex" justifyContent="flex-end">
                      <Button colorScheme="blue" mr={3}
                        onClick={previouscampaignsBtn}
                        disabled={isSubmitting}
                        sx={{
                          _focus: {
                            boxShadow: 'none',  // Removes the focus box shadow
                          }
                        }}
                      >
                        <Text as="span" ms="4px" fontSize="16px" fontWeight="400">Proceed</Text>
                      </Button>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel pt="8">
                  <Box textAlign="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center">
                    <Text as="p" fontSize="18px" fontWeight="700" color="#1A202C" pb="8px">Upload Leads</Text>
                    <Text as="p" fontSize="14px" fontWeight="400" color="#6C757D" pb="24px">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took </Text>
                    <Divider orientation='horizontal' color="#E2E8F0" mb="24px" />
                  </Box>
                  <Box textAlign="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center">
                    <Image src={ExcelLogo} alt='deleteicon' />
                    <Input
                      type="file"
                      ref={fileInputRef}
                      display="none" // Hide the file input
                      onChange={handleFileChange}
                      accept=".csv" // Only allow CSV files
                    />
                    <Button
                      colorScheme="blue"
                      mb="12px"
                      mt="12px"
                      onClick={handleExcelButton}
                      sx={{
                        _focus: {
                          boxShadow: 'none', // Removes the focus box shadow
                        },
                      }}
                    >
                      <Text as="span" ms="4px" fontSize="16px" fontWeight="400">
                        Upload file
                      </Text>
                    </Button>
                    <Text as="p" fontSize="12px" fontWeight="400" color="#6C757D" pb="24px">or drag and drop it here</Text>
                    <Text as="p" fontSize="12px" fontWeight="400" color="#6C757D" pb="24px">
                      Download a{' '}
                      <Text
                        as="a"
                        href={CsvFile} // Path to your sample CSV file in the public folder
                        download="sample.csv" // Name for the downloaded file
                        color="#7C2FF1"
                        // textDecoration="underline"
                        _hover={{ color: "#9F40FF" }}
                      >
                        sample CSV
                      </Text>
                    </Text>

                  </Box>
                </TabPanel>
                <TabPanel pt="8">
                  <GMBSearchForCreateCampaign dataForClose={dataForCloseModal} campaignId={campaignId} campaignName={campaignNames} />

                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CampaignTrigger isOpenCampaignTrigger={isOpenCampaignTrigger} onCloseCampaignTrigger={onCloseCampaignTrigger} campaignId={campaignId} setIsOpenTargetAudience={setIsOpenTargetAudience} campaignName={campaignNames} fetchData={fetchData} currentPage={currentPage} itemsPerPages={itemsPerPages} />
      <CancelThisCampaign isCancelThisCampaign={isCancelThisCampaign} onCancelThisCampaign={onCancelThisCampaign} sendData={handleChildData}
      />
    </>
  )
}
