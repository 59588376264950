import React from 'react'
import {
    Box,
    Flex,
    Heading,
    SimpleGrid,
    Text
} from '@chakra-ui/react'
import { User } from '../../services/appConfig'
import { DiscoutData, SubscriptionTier, SubscriptionType, activeSubscriptionId } from '../../types/subscription.type'
import { PricingCard } from './PricingCard'
import { PricingApiCard } from './PricingApiCard'

type FreeComponentProps = {
    currentSubscription: User["subscriptionData"];
    discountData?: DiscoutData
    userEmail: string;
    activeSubscriptionId: activeSubscriptionId;
}

export const FreeApiKey = ({ currentSubscription, discountData, userEmail, activeSubscriptionId }: FreeComponentProps) => {
    const [subscriptionTier, setSubscriptionTier] = React.useState<SubscriptionTier>('MONTHLY');

    const isCurrentPlan = React.useCallback((type: SubscriptionType) => {
        return currentSubscription?.name === type;
    }, [currentSubscription]);


    // const onChangeTier = React.useCallback(
    //     (tier: number) => {
    //         if (tier === 0) {
    //             setSubscriptionTier('MONTHLY');
    //         } else {
    //             setSubscriptionTier('YEARLY');
    //         }
    //     }, []
    // );

    const getLTDPricingCrrd = React.useCallback((type: activeSubscriptionId) => {
        if (type === 'APPSUMO_TIER1') {
            return <PricingCard
                showPricingBtn={false}
                tier={'LIFETIME'}
                subscriptionData={{
                    type: 'AppSumo tier 1',
                    pageCount: 2000,
                    tokenSize: '1M',
                    projectCount: 20
                }}
                discountData={discountData}
                isCurrentSubscription={isCurrentPlan('Base')}
                userEmail={userEmail}
            />
        } else if (type === 'APPSUMO_TIER2') {
            return <PricingCard
                showPricingBtn={false}
                tier={'LIFETIME'}
                subscriptionData={{
                    type: 'AppSumo tier 2',
                    pageCount: 5000,
                    tokenSize: '2.5M',
                    projectCount: 50
                }}
                discountData={discountData}
                isCurrentSubscription={isCurrentPlan('Base')}
                userEmail={userEmail}
            />
        } else if (type === 'APPSUMO_TIER3') {
            return <PricingCard
                showPricingBtn={false}
                tier={'LIFETIME'}
                subscriptionData={{
                    type: 'AppSumo tier 3',
                    pageCount: 10000,
                    tokenSize: '5M',
                    projectCount: 'unlimited'
                }}
                discountData={discountData}
                isCurrentSubscription={isCurrentPlan('Base')}
                userEmail={userEmail}
            />
        }
    }, [discountData, isCurrentPlan, userEmail]);

    const getPricingBlockLTD = React.useCallback(() => {
        if (currentSubscription?.type === 'LIFETIME') {
            return <Box minW="100%" p="24px" backgroundColor="white" boxShadow="sm" borderRadius="8px" mb="6">
                <Flex direction="column" alignItems="center">
                    <Heading as='h5' size='sm' color="#7C2FF1">
                    Current plan
                    </Heading>
                    <Text textAlign="center" color="#6C757D" fontSize="sm" mt="8px" fontWeight="400">
                        You are on a lifetime plan purchased from AppSumo.
                    </Text>
                    <Flex direction="column" maxW="300px" minW={"300px"}>
                        {getLTDPricingCrrd(activeSubscriptionId)}
                    </Flex>
                </Flex>
            </Box>
        }
        return null

    }, [activeSubscriptionId, currentSubscription?.type, getLTDPricingCrrd]);

   

    return (
        <>
           
           
            <Box minW="100%" p={8} pt={12} pb={1} backgroundColor="white" boxShadow="sm" borderRadius="8px">
                <Flex justify="center" mb={12}>
                    <Flex
                        direction="column"
                        align="center"
                        maxW="xl"
                    >
                        <Heading as='h5' size='18px' color="#1A202C" mb="8px">
                        API Key Plan
                    </Heading>
                        <Text textAlign="center" color="#6C757D" fontSize="sm" mt="8px" fontWeight="400">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                    </Text>
                       
                    </Flex>
                </Flex>
                <Text as="span">
                <SimpleGrid columns={[1, 1, 1, 3]} spacing="6"    templateColumns={{ lg: "repeat(3, minmax(0, 1fr))", sm: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))" }}>
                       
                        <PricingApiCard
                            tier={subscriptionTier}
                            subscriptionData={{
                                type: 'Standard',
                                pageCount: 1000,
                                tokenSize: '1M',
                                projectCount: 1
                            }}
                            discountData={discountData}
                            isCurrentSubscription={isCurrentPlan('Standard')}
                            userEmail={userEmail}
                        />
                        <PricingApiCard
                            tier={subscriptionTier}
                            subscriptionData={{
                                type: 'Premium',
                                pageCount: 2500,
                                tokenSize: '2.5M',
                                projectCount: 3
                            }}
                            discountData={discountData}
                            isCurrentSubscription={isCurrentPlan('Premium')}
                            userEmail={userEmail}
                            isPopular
                        />
                        <PricingApiCard
                            tier={subscriptionTier}
                            subscriptionData={{
                                type: 'Enterprise',
                                pageCount: 10000,
                                tokenSize: '10M',
                                projectCount: "Unlimited"
                            }}
                            discountData={discountData}
                            isCurrentSubscription={isCurrentPlan('Enterprise')}
                            userEmail={userEmail}
                        />
                    </SimpleGrid>
                </Text>
                <Flex
                    mt={8}
                    mb={12}
                    w="100%"
                    justifyContent="center"
                >
                   
                </Flex>
            </Box>
        </>
    )
}